import * as rpc from 'pm-rpc';
import { DocumentServicesObject } from '@wix/document-services-types';

function setEditorAPI(
  documentServices: DocumentServicesObject,
  namespace: string,
  _platformEditorAPIMethods: any, // TODO fix when we type the host types,
) {
  rpc.api.set(namespace, _platformEditorAPIMethods, {
    onApiCall: documentServices.platform.reportAPICallFedOps,
  });
}

function onSetAppPublicAPI(
  documentServices: DocumentServicesObject,
  apiName: string,
) {
  return documentServices.platform
    .requestAPIFromWorker(apiName)
    .then((api: unknown) => {
      rpc.api.set(apiName, api);
    });
}

export { setEditorAPI, onSetAppPublicAPI };
