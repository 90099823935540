import {
  DocumentServicesObject,
  CompRef,
  AppData,
  AppDataComponent,
} from '@wix/document-services-types';
import {
  InnerElementsPathsBuilderStructures,
  InnerElementsHierarchyStructure,
  Store,
  WixComponentManifest,
} from '@wix/editor-platform-manifest-services';

function isBuilderComponentType(compType: string) {
  return compType.startsWith('platform.builder');
}

function findBuilderComponentTypes(appData?: AppData): string[] {
  if (!appData?.components) {
    return [];
  }

  return appData.components.reduce(
    (acc: string[], component: AppDataComponent) => {
      if (
        // @ts-expect-error
        isBuilderComponentType(component.data?.componentModel.componentType)
      ) {
        // @ts-expect-error
        acc.push(component.data?.componentModel.componentType);
      }
      return acc;
    },
    [],
  );
}

function createInitialInnerElementsPathsBuilderStructures(
  documentServicesAPI: DocumentServicesObject,
  manifestStore: Store,
  initialCompRefs?: CompRef[],
): {
  compPointer: CompRef;
  innerElements: WixComponentManifest['elements'];
}[] {
  return Object.entries(manifestStore).flatMap(
    ([compType, manifest]: [string, WixComponentManifest | undefined]) => {
      const compRefs =
        initialCompRefs ?? documentServicesAPI.components.get.byType(compType);

      if (!compRefs.length) {
        return [];
      }

      return compRefs.map((compRef) => {
        const innerElements = manifest?.elements;

        return {
          compPointer: compRef,
          innerElements,
        };
      });
    },
  );
}

function buildHierarchy(
  innerElements: WixComponentManifest['elements'],
): InnerElementsHierarchyStructure[] {
  return Object.entries(innerElements || {}).flatMap(
    ([key, element]: [string, WixComponentManifest | undefined]) => {
      if (element === undefined) {
        return [];
      }

      if (element.elements) {
        return {
          key,
          selector: element?.selector,
          innerElements: buildHierarchy(element.elements),
        };
      } else {
        return {
          key,
          selector: element?.selector,
          innerElements: [],
        };
      }
    },
  ) as InnerElementsHierarchyStructure[];
}

function createInnerElementsPathsBuilderStructures(
  documentServicesAPI: DocumentServicesObject,
  manifestStore: Store,
  initialCompRefs?: CompRef[],
): InnerElementsPathsBuilderStructures {
  const initialStructure = createInitialInnerElementsPathsBuilderStructures(
    documentServicesAPI,
    manifestStore,
    initialCompRefs,
  );

  return initialStructure.map(({ compPointer, innerElements }) => {
    const hierarchy = buildHierarchy(innerElements);
    return {
      compPointer,
      innerElements: hierarchy,
    };
  });
}

function getComponentTypesOnPage(
  documentServicesAPI: DocumentServicesObject,
  pageRef: CompRef,
) {
  return documentServicesAPI.components
    .getChildren(pageRef, true)
    .reduce((acc: string[], compRef: CompRef) => {
      const compType = documentServicesAPI.components.getType(compRef);
      if (isBuilderComponentType(compType)) {
        acc.push(compType);
      }
      return acc;
    }, []);
}

function getCompTypesOnCurrentPage(
  documentServicesAPI: DocumentServicesObject,
) {
  const currentPageRef = documentServicesAPI.pages.getCurrentPage();
  const compTypes = getComponentTypesOnPage(
    documentServicesAPI,
    currentPageRef,
  );

  return compTypes;
}

function createBuildPathsFn(
  documentServicesAPI: DocumentServicesObject,
  getManifestsByCompTypes: (compTypes: string[]) => Store,
) {
  return function buildPaths(
    loadedCompTypes: string[],
    initialComponent?: {
      compRef: CompRef;
      compType: string;
    },
  ) {
    if (
      initialComponent &&
      !loadedCompTypes.includes(initialComponent.compType)
    ) {
      loadedCompTypes.push(initialComponent.compType);
    }

    const initialCompRefs = initialComponent
      ? [initialComponent.compRef]
      : undefined;

    loadedCompTypes.length > 0 &&
      documentServicesAPI.components.innerElements.buildPaths(
        createInnerElementsPathsBuilderStructures(
          documentServicesAPI,
          getManifestsByCompTypes(loadedCompTypes),
          initialCompRefs,
        ),
      );
  };
}

function handleErrors(error: Error) {
  // TODO: handle errors in a proper way
  console.error(error);
}

export {
  isBuilderComponentType,
  createInnerElementsPathsBuilderStructures,
  handleErrors,
  findBuilderComponentTypes,
  createBuildPathsFn,
  getCompTypesOnCurrentPage,
};
