type IWorkerType = 'internal';

class WorkerService {
  private workers: Map<IWorkerType, Worker> = new Map();

  setWorker(workerType: IWorkerType, worker: Worker) {
    this.workers.set(workerType, worker);
  }
  getWorker(workerType: IWorkerType) {
    return this.workers.get(workerType);
  }
  clear() {
    this.workers.clear();
  }
}

export default new WorkerService();
