import {
  BaseError,
  createErrorBuilder,
} from '@wix/public-editor-platform-errors';

export enum EditorPlatformBuilderServicesErrorCode {
  EERegistryCreationError = 'EE Registry creation error',
  ComponentNotExistsInEERegistry = "Component doesn't exist in EE Registry",
  CanNotLoadComponentFromTheEERegistry = "Can't load component from the EE Registry",
  MultipleLoadersFailed = 'Multiple loaders are failed to load components from the EE Registry',
  LoadInitialManifestsError = "Can't load initial manifests from the EE Registry",
}

class EditorPlatformBuilderServicesError extends BaseError<EditorPlatformBuilderServicesErrorCode> {
  constructor(message: string, code: EditorPlatformBuilderServicesErrorCode) {
    super(message, code, 'Editor Platform Manifest Services Error');
  }

  withMultipleErrors(errors: Error[]) {
    this.state.errors = `Multiple errors occurred: ${errors
      .map((e) => e.message)
      .join(', ')}`;
    return this;
  }

  withComponentType(componentType: string) {
    this.state.componentType = componentType;
    return this;
  }
}

export const createEditorPlatformBuilderServicesError = createErrorBuilder<
  EditorPlatformBuilderServicesErrorCode,
  EditorPlatformBuilderServicesError
>(EditorPlatformBuilderServicesError);
