import { DocumentServicesObject } from '@wix/document-services-types';
import { getCanvasOverlayContainer } from '../../../ui/canvasOverlay/canvasOverlayContainer';
import { IWorkspaceApiAdapter } from '../../utils/workspaceApiAdapter';
import { overlayStore } from './overlayStore';
import { siteHeightStore, updateSiteHeight } from './siteHeightStore';

export const init = (
  documentServicesAPI: DocumentServicesObject,
  workspaceAPI: IWorkspaceApiAdapter,
): void => {
  documentServicesAPI.platform.registerToAppRemoved(
    (appDefinitionId: string) => {
      overlayStore.remove(appDefinitionId);
    },
  );

  documentServicesAPI.registerToSiteChanged(() =>
    updateSiteHeight(documentServicesAPI),
  );

  workspaceAPI.contributeStageComponent(() =>
    getCanvasOverlayContainer(overlayStore.register, siteHeightStore.register),
  );
};

export { overlayStore };
