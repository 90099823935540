import { HttpClient } from '@wix/http-client';
import type {
  AppDataComponent,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { CompRef } from '@wix/document-services-types';
import type { PluginInstallationParams } from '@wix/editor-platform-host-integration-apis';
import type { PluginPlacement } from '@wix/ambassador-devcenter-appsruntimereader-v1-app-runtime-data/types';
import {
  ComponentType,
  WidgetPluginComponentData,
} from '@wix/ambassador-app-service-webapp/types';
import { SlotData, WidgetPointer } from '../../types/widgetPlugins';
import { findIndex } from 'lodash';
import { resolveHostAPIBySlotRef } from './plugins/hostAPI/hostAPI';

interface SlotsResponse {
  slots: SlotData[];
}

const getSlotComponentRef = (
  documentServicesAPI: DocumentServicesObject,
  slot: SlotData,
): CompRef | null => {
  const VIRTUAL_SLOT_ID_DIVIDER = '_vs_';
  const isBlocksSlot = slot?.scope?.componentIds?.length;

  if (isBlocksSlot) {
    const [hostWidgetId] = slot!.scope!.componentIds!;
    const hostWidgetCompRef =
      documentServicesAPI.components.get.byId(hostWidgetId);

    if (!hostWidgetCompRef) {
      return null;
    }

    const widgetCompSlots =
      documentServicesAPI.components.slots.getWidgetSlots(hostWidgetCompRef);

    if (!widgetCompSlots) {
      return null;
    }

    const slotComponent = widgetCompSlots?.find(
      ({ role }) => role === slot.slotId,
    );

    if (!slotComponent) {
      return null;
    }

    return slotComponent.compRef as CompRef;
  } else {
    const widgetCompRef = documentServicesAPI.components.get.byId(
      slot.componentId,
    );

    if (!widgetCompRef) {
      return null;
    }

    return {
      id: `${widgetCompRef.id}${VIRTUAL_SLOT_ID_DIVIDER}${slot.slotId}`,
      type: widgetCompRef.type,
    };
  }
};

const getAvailableSlots = async (
  documentServicesAPI: DocumentServicesObject,
  pluginPlacements: PluginPlacement[] = [],
): Promise<SlotData[]> => {
  const META_SITE_APPLICATION_ID = '-666';
  const getAppToken = () =>
    documentServicesAPI.platform.getAppDataByApplicationId(
      META_SITE_APPLICATION_ID,
    )?.instance;

  const httpClient = new HttpClient({
    baseURL: '/_serverless/editor-platform-widget-plugins/',
    getAppToken,
  });

  if (!pluginPlacements?.length) {
    return [];
  }

  const queryParams = new URLSearchParams();
  queryParams.append('placements', JSON.stringify(pluginPlacements));
  const res = await httpClient.get<SlotsResponse>(
    `/slots?${queryParams.toString()}`,
  );

  return res.data.slots;
};

function sortSlotsByPluginPlacements(
  slots: SlotData[],
  placements: PluginPlacement[],
) {
  const sortedSlots = [...slots].sort(function (a, b) {
    return (
      findIndex(placements, { widgetId: a.widgetId, slotId: a.slotId }) -
      findIndex(placements, { widgetId: b.widgetId, slotId: b.slotId })
    );
  });
  return sortedSlots;
}

const getWidgetPluginsComponentData = (
  components: AppDataComponent[],
): WidgetPluginComponentData[] => {
  const isPluginCompType = (comp: AppDataComponent) =>
    comp.type === ComponentType.WIDGET_PLUGIN;

  // WidgetPluginComponentData has an explicit type as AppDataComponent['data'] doesn't provide a correct type for all possible component types
  // @ts-expect-error
  return components?.filter(isPluginCompType).map((w) => w.data) ?? [];
};

const getAllPluginsInstallationParams = async (
  documentServicesAPI: DocumentServicesObject,
  appDefinitionId: string,
  components: AppDataComponent[],
): Promise<PluginInstallationParams[]> => {
  const widgetPlugins = getWidgetPluginsComponentData(components);

  if (widgetPlugins?.length === 0) {
    return [];
  }

  const result: PluginInstallationParams[] = [];

  for (const plugin of widgetPlugins) {
    if (!plugin.installation?.base?.autoAdd) {
      continue;
    }

    const availableSlots = await getAvailableSlots(
      documentServicesAPI,
      plugin.placements,
    );

    if (!availableSlots.length) {
      continue;
    }

    const slots = sortSlotsByPluginPlacements(
      availableSlots,
      plugin.placements || [],
    );

    const firstFreeSlotIndex = slots.findIndex((slot) => {
      const slotComponentRef = getSlotComponentRef(documentServicesAPI, slot);
      if (!slotComponentRef) {
        return false;
      }
      const api = resolveHostAPIBySlotRef(slotComponentRef);
      try {
        const slotData = api.getWidgetSlot(
          documentServicesAPI,
          slotComponentRef,
        );
        return !slotData?.pluginInfo;
      } catch (e) {
        return false;
      }
    });

    if (firstFreeSlotIndex === -1) {
      continue;
    }
    const firstFreeSlot = slots.splice(firstFreeSlotIndex, 1)[0];

    result.push({
      slotComponentRef: getSlotComponentRef(
        documentServicesAPI,
        firstFreeSlot,
      )!,
      widgetRef: {
        appDefinitionId,
        widgetId: plugin.referenceComponentId!,
      },
      pageId: firstFreeSlot.pageId,
    });
  }

  return result;
};

const getPluginInstallationParams = async (
  documentServicesAPI: DocumentServicesObject,
  appDefinitionId: string,
  components: AppDataComponent[],
): Promise<PluginInstallationParams | null> => {
  const widgetPlugins = getWidgetPluginsComponentData(components);

  if (widgetPlugins?.length === 0) {
    return null;
  }

  for (const plugin of widgetPlugins) {
    if (!plugin.installation?.base?.autoAdd) {
      continue;
    }

    const availableSlots = await getAvailableSlots(
      documentServicesAPI,
      plugin.placements,
    );

    if (!availableSlots.length) {
      continue;
    }

    const [slot] = sortSlotsByPluginPlacements(
      availableSlots,
      plugin.placements || [],
    );
    const slotComponentRef = getSlotComponentRef(documentServicesAPI, slot);

    if (!slotComponentRef) {
      return null;
    }

    const widgetRef: WidgetPointer = {
      appDefinitionId,
      widgetId: plugin.referenceComponentId!,
    };

    return {
      slotComponentRef,
      widgetRef,
      pageId: slot.pageId,
    };
  }

  return null;
};

export { getPluginInstallationParams, getAllPluginsInstallationParams };
