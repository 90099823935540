import { PageRef } from '@wix/document-services-types';
import {
  BaseError,
  createErrorBuilder,
} from '@wix/public-editor-platform-errors';

export enum UnifiedComponentsInstallationErrorCode {
  provisionAppsFailed = 'provisionAppsFailed',
  noAppRecivedFromProvision = 'noAppRecivedFromProvision',
  nonUnifiedInstallationFailed = 'nonUnifiedInstallationFailed',
  nonUnifiedComponentInUnifiedApp = 'nonUnifiedComponentInUnifiedApp',
  addingUnifiedComponentFailed = 'addingUnifiedComponentFailed',
  homePageNavigationFailed = 'homePageNavigationFailed',
  addingCodePackagesFailed = 'addingCodePackagesFailed',
  runningEditorScriptFailed = 'runningEditorScriptFailed',
  navigateToAppEndPageFailed = 'navigateToAppEndPageFailed',
}

type UnifiedComponents =
  | 'non-unified-component'
  | 'unified-page'
  | 'unified-widget'
  | 'builder-component';

export type UnifiedComponentsError =
  | UnifiedComponentsInstallationError
  | AddUnifiedComponentsError;

const inferExtraDetailsForBI = (
  error: UnifiedComponentsError,
): {
  errorCode: string;
  errorMessage: string;
  errorData: string;
  errorDetails: string;
  errorType: string;
  type?: UnifiedComponents;
} => {
  return {
    errorCode: error.code,
    errorMessage: error?.getMessage(),
    errorData: error?.getDisplayMessage(),
    errorDetails: error?.getBreadcrumbs()?.toString(),
    errorType: '',
    type: error.componentType,
  };
};

export class UnifiedComponentsInstallationError extends BaseError<UnifiedComponentsInstallationErrorCode> {
  constructor(message: string, code: UnifiedComponentsInstallationErrorCode) {
    super(message, code, 'Unified Components Installation Error');
  }
  componentType?: UnifiedComponents;

  withAppDefIds(appDefinitionId: string | string[]) {
    this.state.appDefinitionId = Array.isArray(appDefinitionId)
      ? JSON.stringify(appDefinitionId)
      : appDefinitionId;
    return this;
  }

  withComponentGUID(componentGUID: string, type: UnifiedComponents) {
    this.state.componentGUID = componentGUID;
    this.state.componentType = type;
    return this;
  }

  getExtraDetailsForBI() {
    return inferExtraDetailsForBI(this);
  }

  get appDefIds() {
    return this.state.appDefinitionId;
  }
}

export const createUnifiedComponentsInstallationError = createErrorBuilder<
  UnifiedComponentsInstallationErrorCode,
  UnifiedComponentsInstallationError
>(UnifiedComponentsInstallationError);

export enum AddUnifiedComponentsErrorCode {
  creatingNewPageFailed = 'creatingNewPageFailed',
  pageHaveNoSlugDefinition = 'pageHaveNoSlugDefinition',
  navigationToNewPageFailed = 'navigationToNewPageFailed',
  creatingNewSectionFailed = 'creatingNewSectionFailed',
  addingWidgetIntoSectionFailed = 'addingWidgetIntoSectionFailed',
  widgetGuidNotFound = 'widgetGuidNotFound',
  addingBlocksWidgetFailed = 'addingBlocksWidgetFailed',
  addingCustomElementWidgetFailed = 'addingCustomElementWidgetFailed',
  addingBuilderComponentFailed = 'addingBuilderComponentFailed',
  widgetTypeNotSupported = 'widgetTypeNotSupported',
  setWidgetFullWidthFailed = 'setWidgetFullWidthFailed',
  manifestLoadingFailed = 'manifestLoadingFailed',
}

class AddUnifiedComponentsError extends BaseError<AddUnifiedComponentsErrorCode> {
  constructor(message: string, code: AddUnifiedComponentsErrorCode) {
    super(message, code, 'Add Unified Component Error');
  }
  pageRef?: PageRef;
  isUnifiedPage?: boolean;
  componentType?: UnifiedComponents;
  builderComponentType?: string;

  withUserComponentId(componentId: string, type: UnifiedComponents) {
    this.componentType = type;
    this.state.componentId = componentId;
    return this;
  }

  withBuilderComponentType(type: string) {
    this.builderComponentType = type;
    return this;
  }

  withPageRef(pageRef: PageRef, isUnified: boolean) {
    this.pageRef = pageRef;
    this.isUnifiedPage = isUnified;
    this.state.pageRef = JSON.stringify(pageRef);
    return this;
  }

  getExtraDetailsForBI() {
    return {
      ...inferExtraDetailsForBI(this),
      pageType: this.isUnifiedPage ? 'unified-page' : 'editor',
      pageId: this.pageRef?.pageId,
    };
  }
}

export const createAddUnifiedComponentsError = createErrorBuilder<
  AddUnifiedComponentsErrorCode,
  AddUnifiedComponentsError
>(AddUnifiedComponentsError);
