import { overrideImplementation } from '@wix/editor-platform-sdk-implementation';
import { init, getWorkerManager } from './bootstrap/init';
import {
  installApps,
  createDependenciesDriver,
} from './services/installServiceEditor';
import {
  silentInstall,
  isSilentInstallRunning,
} from './services/silentInstallService';
import {
  getComponentApp,
  handleEssentialsComponentsBeforeDelete,
} from './services/componentService';
import { getPlatformizedComponentData } from './services/PlatformizedComponentsService';
import {
  setEditorAPI,
  onSetAppPublicAPI,
} from './bootstrap/platformPostMessageService';
import { fetchAppsNames } from './utils/appMarketService';
import {
  getComponentIdentifiers,
  isComponentEssentialByRef,
  isPageDuplicatable,
  isPageEssential,
  isWidgetStretchable,
} from './services/unifiedComponents/componentSettings';
import {
  autoInstallWithNavigation,
  autoInstall,
} from './services/plugins/pluginAutoPopulateService';
import * as canvasOverlayService from './services/canvasOverlayService';
import * as pageReplaceService from './services/pageReplaceService';
import * as toolsEntryService from './services/toolsEntryService';
import * as viewStateService from './services/viewStateService';
import * as slotsService from './services/slotsService';

const API = {
  init,
  setEditorAPI,
  applications: {
    setAppPublicAPI: onSetAppPublicAPI,
    createDependenciesDriver,
    install: installApps,
    fetchNames: fetchAppsNames,
    silentInstall,
    isSilentInstallRunning,
  },
  getWorkerManager,
  pages: {
    isPageEssential,
    isPageDuplicatable,
  },
  components: {
    getComponentApp,
    handleEssentialsComponentsBeforeDelete,
    getPlatformizedComponentData,
    isWidgetStretchable,
    getComponentIdentifiers,
    isComponentEssentialByRef,
  },
  sdk: {
    overrideImplementation,
  },
  pageReplace: {
    ...pageReplaceService,
  },
  canvasOverlay: {
    hideAll: () => {
      canvasOverlayService.overlayStore.hideAll();
    },
    unhideAll: () => {
      canvasOverlayService.overlayStore.unhideAll();
    },
  },
  viewState: viewStateService,
  toolsEntry: toolsEntryService,
  slots: {
    getPluginInstallationParams: slotsService.getPluginInstallationParams,
    getAllPluginsInstallationParams:
      slotsService.getAllPluginsInstallationParams,
    autoInstall: {
      install: autoInstall,
      installWithNavigation: autoInstallWithNavigation,
    },
  },
};

export default API;
