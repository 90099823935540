import { DocumentServicesObject } from '@wix/document-services-types';
import {
  ManifestDefinitions,
  EditorPointer,
  ManifestServices,
} from '@wix/editor-platform-manifest-services';

import { createManifestLoadListeners } from './listeners';

import {
  createBuildPathsFn,
  getCompTypesOnCurrentPage,
  handleErrors,
  isBuilderComponentType,
} from '../../utils/builderUtils';

export function initManifestServices(
  documentServicesAPI: DocumentServicesObject,
  manifestServices: ManifestServices,
) {
  const buildPaths = createBuildPathsFn(
    documentServicesAPI,
    manifestServices.getManifestsByCompTypes,
  );

  const { handleCustomAppEvents, handleNavigateToPage } =
    createManifestLoadListeners(
      documentServicesAPI,
      manifestServices,
      buildPaths,
    );

  const initialPageChildrenComps =
    getCompTypesOnCurrentPage(documentServicesAPI);

  manifestServices
    .loadAndResolveManifestsByCompTypes(initialPageChildrenComps)
    .then(buildPaths)
    .catch(handleErrors);

  documentServicesAPI.registerToSiteChanged(handleNavigateToPage);

  documentServicesAPI.platform.subscribeOnCustomAppEvents(
    handleCustomAppEvents,
  );

  const getValueFromManifest =
    (definition: ManifestDefinitions) =>
    <T>({ compRef, innerElementPath }: EditorPointer, property?: string) => {
      const compType = documentServicesAPI.components.getType(compRef);

      return manifestServices.getValueFromManifest(
        { compType, innerElementPath },
        definition,
        property,
      ) as T;
    };

  return {
    behaviors: {
      getValueFromManifest: getValueFromManifest(ManifestDefinitions.behaviors),
    },
    get: {
      rootManifestData: getValueFromManifest(
        ManifestDefinitions.rootManifestData,
      ),
      styleDefinition: getValueFromManifest(ManifestDefinitions.style),
    },
    internal: {
      loadAndResolveManifestsByCompTypes:
        manifestServices.loadAndResolveManifestsByCompTypes.bind(
          manifestServices,
        ),
      getCompType: getValueFromManifest(ManifestDefinitions.compType),
    },
    isBuilderComponentType,
  };
}
