import { DsItem } from '@wix/document-services-types';

export const getComponentId = (dataItem: DsItem | null): string | undefined => {
  const settings = dataItem?.settings;
  const parsedSettings = settings ? JSON.parse(settings) : undefined;
  return (
    dataItem?.widgetId ||
    parsedSettings?.devCenterWidgetId ||
    parsedSettings?.componentId
  );
};

export const getAppDefId = (dataItem: DsItem | null): string | undefined =>
  dataItem?.appDefinitionId || dataItem?.applicationId;
