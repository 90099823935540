import React, { useRef } from 'react';

interface ICanvasOverlayProps {
  appDefinitionId: string;
  url: string;
  visible: boolean;
}

export const CanvasOverlay: React.FC<ICanvasOverlayProps> = (
  props: ICanvasOverlayProps,
) => {
  const { appDefinitionId, url, visible } = props;
  // enforce that iframe will not navigate to other url
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const onLoad = () => {
    if (iframeRef.current && iframeRef.current.src !== url) {
      iframeRef.current.src = url;
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: visible ? 'block' : 'none',
      }}
      data-aid={`${appDefinitionId}-overlay`}
    >
      <iframe
        ref={iframeRef}
        onLoad={onLoad}
        style={{ width: '100%', height: '100%' }}
        src={url}
        title={`${appDefinitionId} Addon`}
      />
    </div>
  );
};
