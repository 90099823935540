import { reportBi } from '../../../essentials';

const WIDGET_VIEW_STATE_CHANGED_EVENT_ID = 101;

export function sendWidgetViewStateChangedBiEvent(props: {
  appDefId: string;
  hostAppId: number;
  componentId: string;
  modes: string[];
  rootWidget: boolean;
}) {
  reportBi(WIDGET_VIEW_STATE_CHANGED_EVENT_ID, {
    app_def_id: props.appDefId,
    host_app_id: props.hostAppId,
    component_id: props.componentId,
    modes: props.modes,
    root_widget: props.rootWidget,
  });
}
