import { CompRef, DocumentServicesObject } from '@wix/document-services-types';
import { isComponentEssential } from './unifiedComponents/componentSettings';
import { AppData } from '../../types/unifiedComponents';
import { PlatformContext } from '../../types/platformApi';

const appWidget = 'platform.components.AppWidget';
const appController = 'platform.components.AppController';

const getAppFromController = (
  documentServices: DocumentServicesObject,
  controllerRef: CompRef,
) => {
  const { applicationId } =
    documentServices.components.data.get(controllerRef) || {};
  const { applicationId: actualApplicationId } =
    documentServices.tpa.app.getDataByAppDefId(applicationId) || {};
  return { applicationId: actualApplicationId, appDefinitionId: applicationId };
};

const getAppFromTPA = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) => {
  const { applicationId, appDefinitionId } =
    documentServices.components.data.get(compRef) || {};
  return { applicationId, appDefinitionId };
};

export const getComponentApp = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): { appDefinitionId?: string; applicationId?: number } => {
  const compType = documentServices.components.getType(compRef);
  if (compType === appWidget || compType === appController) {
    return getAppFromController(documentServices, compRef);
  }
  const { controllerRef } =
    documentServices.platform.controllers.connections.getPrimaryConnection(
      compRef,
    ) || {};
  if (controllerRef) {
    return getAppFromController(documentServices, controllerRef as CompRef);
  } else {
    return getAppFromTPA(documentServices, compRef);
  }
};

const getComponentsAppsDataWithEssentialsClassification = (
  documentServices: DocumentServicesObject,
  components: CompRef[],
): {
  [appDefId: string]: AppData & {
    essentialsComponents: CompRef[];
    nonEssentialsComponents: CompRef[];
  };
} => {
  const appsData = components.reduce((acc, compRef) => {
    const compData = documentServices.components.data.get(compRef);
    if (compData) {
      const appDefId: string =
        compData?.appDefinitionId || compData?.applicationId;
      if (!acc[appDefId]) {
        acc[appDefId] = {
          ...(appDefId
            ? documentServices.platform.getAppDataByAppDefId(appDefId)
            : {}),
          essentialsComponents: [],
          nonEssentialsComponents: [],
        };
        if (isComponentEssential(compData.widgetId, acc[appDefId])) {
          acc[appDefId].essentialsComponents.push(compRef);
        } else {
          acc[appDefId].nonEssentialsComponents.push(compRef);
        }
      }
    }
    return acc;
  }, {} as any);

  return appsData;
};

export const handleEssentialsComponentsBeforeDelete = async (
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  components: CompRef[],
): Promise<CompRef[]> => {
  let compRefsToDelete: CompRef[] = [];

  const appsData = getComponentsAppsDataWithEssentialsClassification(
    documentServices,
    components,
  );

  for (const appData of Object.values(appsData)) {
    if (appData.essentialsComponents.length > 0) {
      try {
        await context.platformApiMethods.document.application.uninstall(
          appData,
          'TOKEN',
          { openConfirmation: true },
        );
      } catch (e) {
        compRefsToDelete = [
          ...compRefsToDelete,
          ...appData.nonEssentialsComponents,
        ];
      }
    } else {
      compRefsToDelete = [
        ...compRefsToDelete,
        ...appData.nonEssentialsComponents,
      ];
    }
  }

  return compRefsToDelete;
};
