import {
  AppData,
  ComponentRemovedHookFunc,
  ComponentRemovedHookParams,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { getComponentId, getAppDefId } from '../../../utils/component';
import { PlatformApiMethods } from '../../../../types/platformApi';
import { isComponentEssential } from '../componentSettings';

let _isCurrentlyUninstalling = false;

const uninstallApp = async (
  platformApiMethods: PlatformApiMethods,
  appData: AppData,
  shouldOpenConfirmationPanel: boolean,
) => {
  if (!_isCurrentlyUninstalling) {
    _isCurrentlyUninstalling = true;
    await platformApiMethods.document.application.uninstall(
      appData as AppData,
      'token',
      {
        openConfirmation: shouldOpenConfirmationPanel,
      },
    );
    _isCurrentlyUninstalling = false;
  }
};

export const componentRemovedHook = (
  platformApiMethods: PlatformApiMethods,
  documentServicesAPI: DocumentServicesObject,
): ComponentRemovedHookFunc => {
  return async ({ dataItem }: ComponentRemovedHookParams) => {
    const appDefId = getAppDefId(dataItem);
    const componentId = getComponentId(dataItem);
    if (appDefId) {
      const appData =
        documentServicesAPI.platform.getAppDataByAppDefId(appDefId);
      if (
        componentId &&
        appData &&
        isComponentEssential(componentId, appData)
      ) {
        await uninstallApp(platformApiMethods, appData, false);
      }
    }
  };
};
