import { CompRef, PresetsValue, UnitSize } from '@wix/document-services-types';
import { ComponentDefinition } from '@wix/editor-platform-sdk-types';
import { ExtendedPlatformContext } from '../../../../../types/platformApi';
import {
  AppData,
  MainPresets,
  UnifiedWidget,
} from '../../../../../types/unifiedComponents';
import {
  AddUnifiedComponentsErrorCode as ErrorCode,
  createAddUnifiedComponentsError,
} from '../errors';
import { getWidgetAppearanceData } from './addBlocksWidget';

const TOKEN = 'unified-components-installation';

async function loadManifest(
  widgetId: string,
  componentType: string,
  context: ExtendedPlatformContext,
) {
  try {
    await context.manifestServices.internal?.loadAndResolveManifestsByCompTypes(
      [componentType],
    );
  } catch (error) {
    throw createAddUnifiedComponentsError(
      ErrorCode.manifestLoadingFailed,
      'Could not load manifest',
    )
      .withBuilderComponentType(componentType)
      .withUserComponentId(widgetId, 'builder-component')
      .withParentError(error as Error);
  }
}

export async function addBuilderComponent(
  context: ExtendedPlatformContext,
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  containerRef: CompRef,
  preset?: MainPresets,
): Promise<CompRef> {
  const { widgetId, componentModel } = widgetDefinition;
  const { presetId, layout, layouts } = await getWidgetAppearanceData(
    appData,
    widgetDefinition,
    preset,
  );
  const componentType = componentModel.componentType;

  const getCorrectSize = (size?: UnitSize | number) =>
    typeof size === 'number' ? size : size?.value;

  const componentDefinition: ComponentDefinition = {
    componentType,
    type: 'Component',
    layout: {
      ...layout,
      height: getCorrectSize(layout?.height),
      width: getCorrectSize(layout?.width),
    },
    layouts,
  };

  if (presetId) {
    componentDefinition.presets = {
      type: 'PresetData',
      layout: presetId,
      style: presetId,
    } as PresetsValue;
  }

  try {
    await loadManifest(widgetId, componentType, context);

    const compRef = await context.platformApiMethods.document.components.add(
      appData,
      TOKEN,
      {
        componentDefinition,
        pageRef: containerRef,
      },
    );

    return compRef;
  } catch (error) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBuilderComponentFailed,
      'Could not add builder component',
    )
      .withBuilderComponentType(componentType)
      .withUserComponentId(widgetId, 'builder-component')
      .withParentError(error as Error);
  }
}
