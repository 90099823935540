import {
  BaseError,
  createErrorBuilder,
} from '@wix/public-editor-platform-errors';

export enum EditorPlatformUrlResolverErrorCode {
  ManifestUrlNotFound = 'Manifest URL not found',
}

class EditorPlatformUrlResolverError extends BaseError<EditorPlatformUrlResolverErrorCode> {
  constructor(message: string, code: EditorPlatformUrlResolverErrorCode) {
    super(message, code, 'Editor Platform URL Resolver Error');
  }
  componentType?: string;

  withComponentType(type: string) {
    this.componentType = type;
    return this;
  }
}

export const createEditorPlatformUrlResolverError = createErrorBuilder<
  EditorPlatformUrlResolverErrorCode,
  EditorPlatformUrlResolverError
>(EditorPlatformUrlResolverError);
