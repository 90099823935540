import { InitAppOptions } from '@wix/editor-platform-host-integration-apis';

export interface UrlDecorator {
  decorateWithOrigin: (url: string) => string;
}

type OriginValue = 'editor' | 'editor_x';
type KnownOriginType = 'classic' | 'responsive';

function isKnownOriginType(originType: string): originType is KnownOriginType {
  return ['classic', 'responsive'].includes(originType);
}

function getOriginValue(originType: string): OriginValue | null {
  const lowercasedOriginType = originType.toLowerCase();
  if (isKnownOriginType(lowercasedOriginType)) {
    const originValues = {
      classic: 'editor',
      responsive: 'editor_x',
    } as const;
    return originValues[lowercasedOriginType];
  }
  return null;
}

export const createUrlDecorator = (
  initAppOptions?: InitAppOptions,
): UrlDecorator => {
  function decorateWithOrigin(urlString: string) {
    const url = new URL(urlString);
    if (!initAppOptions || url.searchParams.has('origin')) {
      return urlString;
    }

    const {
      origin: { type },
    } = initAppOptions;

    const originValue = getOriginValue(type);
    if (originValue) {
      url.searchParams.append('origin', originValue);
    }

    return url.toString();
  }

  return {
    decorateWithOrigin,
  };
};
