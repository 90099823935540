import {
  DocumentServicesObject,
  PageRef,
  RouterRef,
  RoutersDefinition,
} from '@wix/document-services-types';

function getPageRoleByPageId(
  pages: RoutersDefinition['pages'],
  pageId: PageRef['id'],
): string | undefined {
  return Object.keys(pages).find((roleId) => pages[roleId] === pageId);
}

export enum INFIX {
  VARIANT = 'variant',
  REPLACER = 'replacer',
}

function connectPage(
  documentServices: DocumentServicesObject,
  routerRef: RouterRef,
  pageRef: PageRef,
  newPageRef: PageRef,
  variantId: string | undefined,
  infix: INFIX.REPLACER | INFIX.VARIANT,
): string {
  const routerData = documentServices.routers.get.byRef(routerRef);

  if (!routerData) {
    throw new Error('router data is not available for ref');
  }

  const mainPageRole = getPageRoleByPageId(routerData.pages, pageRef.id);
  if (!mainPageRole) {
    throw new Error('connectPage failed, pageRole is not found');
  }
  const newPageRole = generateRole(
    mainPageRole,
    routerData.pages,
    variantId,
    infix,
  );
  documentServices.routers.pages.connect(
    routerRef,
    newPageRef,
    [newPageRole],
    {},
  );
  return newPageRole;
}

function generateRole(
  roleId: string,
  pages: RoutersDefinition['pages'],
  variantId: string | undefined,
  infix: string,
): string {
  // Generating new replacer role name according the highest replacer index that exist
  const index = Object.keys(pages).reduce(
    (maxIndex, role) =>
      role.startsWith(`${roleId}_${infix}`)
        ? Math.max(
            Number(role.split(`_${infix}`).pop()?.charAt(0)) + 1,
            maxIndex,
          )
        : maxIndex,
    1,
  );
  const variantIdSuffix = variantId ? `-${variantId}` : '';
  return `${roleId}_${infix}${index}${variantIdSuffix}`;
}

export { getPageRoleByPageId, connectPage };
