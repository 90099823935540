import { ComponentPanel } from './types';

export class PanelsStore {
  private _store = new Map<string, Map<string, ComponentPanel>>();

  public getPanel(compType: string, panelType: string): ComponentPanel | null {
    return this._store.get(panelType)?.get(compType) ?? null;
  }

  public setPanel(panelType: string) {
    return (compType: string, panel: ComponentPanel) => {
      if (!this._store.has(panelType)) {
        this._store.set(panelType, new Map());
      }

      this._store.get(panelType)?.set(compType, panel);
    };
  }
}
