import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import {
  openFeedbackForm,
  closeFeedbackForm,
} from '../../services/feedbackService';

export const feedbackEnhancer: PlatformApiEnhancerFunction = (
  _platformApiMethods,
  { documentServices },
) => {
  return {
    editor: {
      feedback: {
        open: openFeedbackForm.bind({}, documentServices),
        close: closeFeedbackForm.bind({}),
      },
    },
  };
};
