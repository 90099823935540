import { ExtendedPlatformContext } from '../../../../../types/platformApi';
import {
  AppData,
  MainPresets,
  UnifiedWidget,
} from '../../../../../types/unifiedComponents';
import {
  AddUnifiedComponentsErrorCode as ErrorCode,
  createAddUnifiedComponentsError,
} from '../errors';
import {
  CompRef,
  ComponentLayoutObject,
  UnitSize,
} from '@wix/document-services-types';
import { getAppDescriptorByAppData } from '@wix/blocks-widget-services/editor';
import { addBuilderComponent } from './addBuilderComponent';

const appDescriptors: Record<
  string,
  Awaited<ReturnType<typeof getAppDescriptorByAppData>>
> = {};

const TOKEN = 'unified-components-installation';

async function getPresetAndSize(
  appData: AppData,
  widgetId: string,
  presetId?: string,
  mobilePresetId?: string,
) {
  if (!appDescriptors[appData.appDefinitionId]) {
    appDescriptors[appData.appDefinitionId] = await getAppDescriptorByAppData(
      appData,
    );
  }
  const appDescriptor = appDescriptors[appData.appDefinitionId];
  const widgetDescriptor = Object.values(appDescriptor?.widgets ?? []).find(
    ({ devCenterWidgetId }) => devCenterWidgetId === widgetId,
  );
  if (!widgetDescriptor) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch widget from app descriptor',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  let preset = widgetDescriptor.presets?.[0];
  if (presetId) {
    preset = widgetDescriptor?.presets?.find(
      (widgetPreset) => widgetPreset.presetId === `#${presetId}`,
    );
  } else {
    presetId = preset?.presetId;
  }
  if (!presetId || !preset) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch preset for blocks widget',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  const size = preset.defaultSize ?? widgetDescriptor.defaultSize;
  if (!size) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch default size for blocks widget',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  return {
    size,
    presetId,
    mobilePreset: {
      id: mobilePresetId || presetId,
      size,
    },
  };
}

export async function getWidgetAppearanceData(
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  preset?: MainPresets,
) {
  const position = { x: 0, y: 0 };
  const isStretched = !!widgetDefinition.isStretched?.desktop;
  const selectedPreset =
    preset ?? widgetDefinition.installation?.widget?.defaultPreset;

  let { desktopPresetId: presetId } = selectedPreset ?? {};

  const { mobilePreset, size, ...defaultValues } = await getPresetAndSize(
    appData,
    widgetDefinition.widgetId,
    presetId,
    selectedPreset?.mobilePresetId,
  );
  presetId = defaultValues.presetId;
  const mobile = mobilePreset;

  const mobileScopedPreset = mobilePreset && {
    layout: mobilePreset.id,
    style: mobilePreset.id,
  };

  const layout = {
    ...size,
    ...position,
    ...(isStretched
      ? {
          docked: {
            left: { px: 0, vw: 0 },
            right: { px: 0, vw: 0 },
          },
        }
      : {}),
  };

  const getCorrectSize = (sizeValue?: UnitSize | number) =>
    typeof sizeValue !== 'number' ? sizeValue : undefined;

  const layouts: Partial<ComponentLayoutObject> = {
    componentLayout: isStretched
      ? {
          type: 'ComponentLayout',
          height: getCorrectSize(size?.height),
          width: { type: 'percentage', value: 100 },
        }
      : {
          type: 'ComponentLayout',
          width: getCorrectSize(size?.width),
          height: getCorrectSize(size?.height),
          minWidth: getCorrectSize(size?.width),
          minHeight: getCorrectSize(size?.height),
        },
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [{ type: 'fr', value: 1 }],
      columns: [{ type: 'fr', value: 1 }],
    },
    itemLayout: {
      id: '',
      type: 'GridItemLayout',
      gridArea: {
        columnStart: 1,
        columnEnd: 2,
        rowStart: 1,
        rowEnd: 2,
      },
      alignSelf: 'start',
      justifySelf: 'start',
      margins: {
        left: { type: 'percentage', value: 0 },
        top: { type: 'px', value: 0 },
      },
    },
  };

  return {
    selectedPreset,
    presetId,
    size,
    mobilePreset,
    mobileScopedPreset,
    mobile,
    layout,
    layouts,
  };
}

async function addBlocksClosedWidget(
  context: ExtendedPlatformContext,
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  containerRef: CompRef,
  preset?: MainPresets,
): Promise<CompRef> {
  const { widgetId } = widgetDefinition;
  const {
    size,
    presetId,
    mobilePreset,
    mobileScopedPreset,
    mobile,
    layout,
    layouts,
  } = await getWidgetAppearanceData(appData, widgetDefinition, preset);

  return new Promise<CompRef>((resolve, reject) =>
    // @ts-expect-error
    context.platformApiMethods.application.appStudioWidgets
      .addWidget(appData, TOKEN, {
        widgetId,
        installationType: 'closed',
        presetIds: presetId
          ? {
              layout: presetId,
              style: presetId,
            }
          : undefined,
        layout,
        layouts,
        containerRef,
        scopedPresets: {
          ...(mobileScopedPreset ? { mobile: mobileScopedPreset } : {}),
        },
        dimensionsByDevice: {
          desktop: size,
          ...(mobilePreset
            ? {
                mobile: mobilePreset.size,
              }
            : mobile),
        },
      })
      .then(resolve)
      .catch((error: Error) =>
        reject(
          createAddUnifiedComponentsError(
            ErrorCode.addingBlocksWidgetFailed,
            'Could not add blocks widget',
          )
            .withUserComponentId(widgetId, 'unified-widget')
            .withParentError(error),
        ),
      ),
  );
}

export async function addBlocksWidgetResolver(
  context: ExtendedPlatformContext,
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  containerRef: CompRef,
  preset?: MainPresets,
): Promise<CompRef> {
  if (
    widgetDefinition.componentModel?.componentType &&
    widgetDefinition.componentModel.componentType ===
      'platform.builder.714ac4e7-d48b-4be2-926a-d6967fbd22a4_9ceb1f35-a054-4d78-8b95-8d942f88eb5b' // for builder test component
  ) {
    return addBuilderComponent(
      context,
      appData,
      widgetDefinition,
      containerRef,
      preset,
    );
  }

  return addBlocksClosedWidget(
    context,
    appData,
    widgetDefinition,
    containerRef,
    preset,
  );
}
