import { WidgetPluginComponentData } from '@wix/ambassador-app-service-webapp/types';
import {
  DocumentServicesObject,
  CompStructure,
  AppDataComponent,
  AppComponent,
  DsItem,
  PresetsValue,
} from '@wix/document-services-types';
import { WidgetPointer } from '@wix/editor-platform-host-integration-apis';
import { PluginInfo } from '../../../../types/widgetPlugins';
import { reportBi } from '../../../../essentials';
import { LogObject } from '@wix/bi-logger-editor-x/v2/types';
import { PluginPlacement } from '@wix/ambassador-devcenter-appsruntimereader-v1-app-runtime-data/types';

export const IFRAME_WIDGET = 'WIDGET';
export const IFRAME_PAGE = 'PAGE';
export const OOI_WIDGET = 'WIDGET_OUT_OF_IFRAME';
export const OOI_PAGE = 'PAGE_OUT_OF_IFRAME';
export const BLOCKS_WIDGET = 'STUDIO_WIDGET';
const SUPPORTED_WIDGETS = [
  IFRAME_WIDGET,
  IFRAME_PAGE,
  OOI_WIDGET,
  OOI_PAGE,
  BLOCKS_WIDGET,
] as const;

const compDefCreators = {
  [IFRAME_WIDGET]: createIFrameComponent,
  [IFRAME_PAGE]: createIFrameComponent,
  [OOI_WIDGET]: createTPAWidgetComponent,
  [OOI_PAGE]: createTPAWidgetComponent,
  [BLOCKS_WIDGET]: createBlocksWidgetComponent,
} as const;
interface LayoutOptions {
  height?: number;
}

export const virtualSlotPlaceholderSeparator = '_vs_';
export const defaultSlotName = 'slot';

export function getResponsiveLayouts(options: LayoutOptions = {}) {
  return {
    type: 'SingleLayoutData',
    componentLayout: {
      type: 'ComponentLayout',
      height: options.height
        ? { type: 'px', value: options.height }
        : { type: 'auto' },
      width: {
        type: 'auto',
      },
    },
    itemLayout: {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      alignSelf: 'stretch',
      justifySelf: 'stretch',
    },
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
  };
}

export async function getDefaultPreset(
  documentServicesAPI: DocumentServicesObject,
  widgetPointer: WidgetPointer,
) {
  const appDescriptor =
    await documentServicesAPI.appStudioWidgets.getAppDescriptor(
      widgetPointer.appDefinitionId,
    );

  const widgetDescriptor = Object.values(appDescriptor.widgets).find(
    (widget) => widget.devCenterWidgetId === widgetPointer.widgetId,
  );

  const [defaultPreset] = widgetDescriptor?.presets ?? [];

  if (!defaultPreset) {
    return undefined;
  }

  const presetId = defaultPreset.presetId.replace('#', '');

  return {
    type: 'PresetData',
    layout: presetId,
    style: presetId,
  };
}

export async function createBlocksWidgetComponent(
  documentServicesAPI: DocumentServicesObject,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
): Promise<CompStructure> {
  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.RefComponent',
    );

  widgetComponent.data = {
    ...widgetComponent.data,
    type: 'WidgetRef',
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };
  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  } as any;

  widgetComponent.presets = (await getDefaultPreset(documentServicesAPI, {
    appDefinitionId,
    widgetId,
  })) as PresetsValue;

  return widgetComponent;
}

export function getWidgetAppComponent(
  documentServicesAPI: DocumentServicesObject,
  widgetPointer: WidgetPointer,
): AppDataComponent {
  const appData = documentServicesAPI.tpa.app.getDataByAppDefId(
    widgetPointer.appDefinitionId,
  );

  if (!appData) {
    throw new Error(
      `Application with appDefinitionId: ${widgetPointer.appDefinitionId} is missing`,
    );
  }
  const widgetAppComponent = appData.components?.find(
    (appComponent) => appComponent.componentId === widgetPointer.widgetId,
  );

  if (!widgetAppComponent) {
    throw new Error(
      `Application "${appData.name}" is missing the widget component with ID: ${widgetPointer.widgetId}`,
    );
  }

  return widgetAppComponent;
}
export function createWidgetPluginComponentDefinition(
  documentServicesAPI: DocumentServicesObject,
  widgetPointer: WidgetPointer,
  applicationId: number,
) {
  const widgetAppComponent = getWidgetAppComponent(
    documentServicesAPI,
    widgetPointer,
  );

  const widgetPluginType = getWidgetPluginType(widgetAppComponent);

  return widgetPluginType === BLOCKS_WIDGET
    ? compDefCreators[widgetPluginType](
        documentServicesAPI,
        widgetPointer,
        applicationId,
      )
    : compDefCreators[widgetPluginType](
        documentServicesAPI,
        widgetPointer,
        applicationId,
        widgetAppComponent as AppComponent,
      );
}

export function createIFrameComponent(
  documentServicesAPI: DocumentServicesObject,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
  widgetPluginAppComponent: AppComponent,
): CompStructure {
  const DEFAULT_INITIAL_HEIGHT = 100;

  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.tpapps.TPAWidget',
    );

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };

  // iFrame needs an initial height to be rendered. Later it can set its own height via Js-SDK
  const initialHeight =
    // @ts-expect-error
    widgetPluginAppComponent?.data?.height || DEFAULT_INITIAL_HEIGHT;

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts({ height: initialHeight }),
  } as any;

  return widgetComponent;
}

export function createTPAWidgetComponent(
  documentServicesAPI: DocumentServicesObject,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
): CompStructure {
  const widgetComponent =
    documentServicesAPI.components.buildDefaultComponentStructure(
      'wysiwyg.viewer.components.tpapps.TPAWidget',
    );

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  } as any;

  return widgetComponent;
}

export function getWidgetPluginType(widgetAppComponent: AppDataComponent) {
  const widgetPluginType = widgetAppComponent?.type;

  if (!SUPPORTED_WIDGETS.includes(widgetPluginType as any)) {
    throw new Error(`Widget-plugin type is unsupported -- ${widgetPluginType}`);
  }

  return widgetPluginType as (typeof SUPPORTED_WIDGETS)[number];
}
export function getPluginMarketData(
  documentServicesAPI: DocumentServicesObject,
  widgetPointer: WidgetPointer,
): WidgetPluginComponentData | undefined {
  const pluginMarketDataComponent = documentServicesAPI.tpa.app
    .getDataByAppDefId(widgetPointer.appDefinitionId)
    ?.components?.find(
      (component) =>
        (component?.data as WidgetPluginComponentData)?.referenceComponentId ===
        widgetPointer.widgetId,
    );
  return pluginMarketDataComponent?.data as WidgetPluginComponentData;
}
export function getPluginInfo(
  documentServicesAPI: DocumentServicesObject,
  slotsComponentData: DsItem | undefined,
): PluginInfo | undefined {
  if (!slotsComponentData) {
    return undefined;
  }

  return {
    ...getPluginMarketData(documentServicesAPI, {
      appDefinitionId: slotsComponentData.appDefinitionId,
      widgetId: slotsComponentData.widgetId,
    })?.marketData,
    widgetId: slotsComponentData.widgetId,
    appDefinitionId: slotsComponentData.appDefinitionId,
  } as PluginInfo;
}

export function reportinPluginBi(logObject: LogObject<unknown>) {
  reportBi(logObject.evid, logObject);
}

export function serializePlacement(placement: PluginPlacement) {
  return (
    placement &&
    `${placement.appDefinitionId}_${placement.widgetId}_${placement.slotId}`
  );
}
