import { PageRef } from '@wix/document-services-types';
import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { addVariantToPage } from '../../services/pageReplaceService';

export const pageReplaceEnhancer: PlatformApiEnhancerFunction = (
  _platformApiMethods,
  { documentServices },
) => {
  return {
    editor: {
      routers: {
        addVariantToPage: (
          pageRef: PageRef,
          variantPageRef: PageRef,
          rule: any,
          variantId: string,
        ) => {
          addVariantToPage(documentServices, {
            pageRef,
            variantPageRef,
            rule,
            variantId,
          });
        },
      },
    },
  };
};
