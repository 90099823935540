import { registry } from '@wix/editor-elements-registry/2.0/editorPlatform';
import { getComponentsLibraries } from '@wix/editor-elements-registry/2.0/toolbox';
import { IExperimentsApiAdapter, ServiceTopology } from './types';
import {
  EditorPlatformBuilderServicesErrorCode,
  createEditorPlatformBuilderServicesError,
} from './errors';

async function getEERegistryComponentsLoaders(
  serviceTopology: ServiceTopology,
  experimentsAPI: IExperimentsApiAdapter,
) {
  try {
    const libraries = getComponentsLibraries({
      serviceTopology,
      url: window.location.href,
      useNewStatics: experimentsAPI.enabled(
        'specs.editor-elements.useNewStatics',
      ),
      experimentalMobileLibrary: experimentsAPI.enabled(
        'specs.mobile-elements.useRegistry',
      ),
    });

    const registryAPI = await registry({
      mode: 'eager',
      libraries,
      options: {
        useEsmLoader: Boolean((window as any).isNewSantaEditorBundle),
      } as any,
    });

    return await registryAPI.getComponentsLoaders();
  } catch (err) {
    throw createEditorPlatformBuilderServicesError(
      EditorPlatformBuilderServicesErrorCode.EERegistryCreationError,
    ).withParentError(err as Error);
  }
}

export async function loadInitialDataFromEERegistry(
  serviceTopology: ServiceTopology,
  experimentsAPI: IExperimentsApiAdapter,
  {
    setManifest,
    setDesignPanel,
  }: {
    setManifest: (componentType: string, manifest: any) => void;
    setDesignPanel: (componentType: string, panel: any) => void;
  },
) {
  const errors: Error[] = [];

  try {
    const loaders = await getEERegistryComponentsLoaders(
      serviceTopology,
      experimentsAPI,
    );

    await Promise.all(
      Object.entries(loaders).map(async ([componentType, loader]) => {
        try {
          const props = await loader();

          if (props.externalComponentManifest) {
            setManifest(componentType, props.externalComponentManifest);
          }

          if (props.designPanel) {
            setDesignPanel(componentType, props.designPanel);
          }
        } catch (err) {
          errors.push(
            createEditorPlatformBuilderServicesError(
              EditorPlatformBuilderServicesErrorCode.CanNotLoadComponentFromTheEERegistry,
              componentType,
            ).withParentError(err as Error),
          );
        }
      }),
    );
  } catch (err) {
    throw createEditorPlatformBuilderServicesError(
      EditorPlatformBuilderServicesErrorCode.LoadInitialManifestsError,
    );
  }

  if (errors.length > 0) {
    throw createEditorPlatformBuilderServicesError(
      EditorPlatformBuilderServicesErrorCode.MultipleLoadersFailed,
    ).withMultipleErrors(errors);
  }
}
