import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { AppData, CompRef } from '@wix/document-services-types';

export const classicDefaultImplementationEnhancer: PlatformApiEnhancerFunction =
  (_platformApiMethods, { documentServices }) => {
    return {
      document: {
        components: {
          setFullWidth: (
            _appData: AppData,
            _token: string,
            options: {
              fullWidth: boolean;
              componentRef: CompRef;
              margins: any;
            },
          ) => {
            if (!options.componentRef) {
              throw new Error('options must include componentRef property');
            }

            if (typeof options.fullWidth !== 'boolean') {
              throw new Error(
                'options must include fullWidth boolean property',
              );
            }

            return new Promise<void>((resolve) => {
              if (options.fullWidth) {
                const { left, right } = options?.margins || {};
                const dockParams = {
                  // vw units must be specified, otherwise the component will not be
                  // considered "full-width" (i.e. `isFullWidth` will return `false`)
                  left: { vw: 0, ...left },
                  right: { vw: 0, ...right },
                };
                documentServices.components.layout.setDock(
                  options.componentRef,
                  dockParams,
                );
              } else {
                documentServices.components.layout.unDock(options.componentRef);
              }
              documentServices.waitForChangesApplied(resolve);
            });
          },
        },
      },
    };
  };
