import {
  CompRef,
  DocumentServicesObject,
  DsItem,
} from '@wix/document-services-types';
import { WidgetPointer } from '@wix/editor-platform-host-integration-apis';
import {
  getPluginInfo,
  createWidgetPluginComponentDefinition,
  virtualSlotPlaceholderSeparator,
} from '../utils/pluginUtils';
import { WidgetSlot } from '../../../../types/widgetPlugins';

export interface OOIWidgetSlot {
  displayName: string;
  name: string;
  interfaces: string[];
}

export const tpaHostAPI = {
  getVirtualSlotPlaceholderData(compRef: CompRef): {
    widgetHostRef: CompRef;
    slotName: string;
  } {
    const [hostId, slotName] = compRef.id.split(
      virtualSlotPlaceholderSeparator,
    );

    return {
      widgetHostRef: {
        id: hostId,
        type: compRef.type,
      },
      slotName,
    };
  },
  getSlotsFromAppManifest(
    documentServicesAPI: DocumentServicesObject,
    widgetCompRef: CompRef,
  ): OOIWidgetSlot[] {
    const { appDefinitionId, widgetId } =
      documentServicesAPI.components.data.get(widgetCompRef);

    if (!appDefinitionId) {
      return [];
    }

    const appManifest =
      documentServicesAPI.platform.getAppManifest(appDefinitionId);

    if (!appManifest) {
      return [];
    }

    const slotsMap: Record<
      string,
      Pick<OOIWidgetSlot, 'displayName' | 'interfaces'>
    > =
      // @ts-expect-error
      appManifest.controllersStageData?.[widgetId]?.default?.slots ?? {};

    return Object.entries(slotsMap).map(([slotName, slotConfig]) => ({
      name: slotName,
      ...slotConfig,
    }));
  },
  getVirtualSlotsPlaceholderCompRef(
    widgetCompRef: CompRef,
    slotName: string,
  ): CompRef {
    return {
      id: `${widgetCompRef.id}${virtualSlotPlaceholderSeparator}${slotName}`,
      type: widgetCompRef.type,
    };
  },
  getWidgetSlotData(
    documentServicesAPI: DocumentServicesObject,
    hostRef: CompRef,
    slot: OOIWidgetSlot,
  ): WidgetSlot {
    const slotId = slot.name;
    const { widgetId, appDefinitionId } =
      documentServicesAPI.components.data.get(hostRef);

    const slotData =
      documentServicesAPI.components.slots.getSlotsData(hostRef)?.[slotId];
    const slotComponentData: DsItem | undefined =
      documentServicesAPI.components.data.get(slotData!);
    return {
      compRef: this.getVirtualSlotsPlaceholderCompRef(hostRef, slotId),
      role: slotId,
      interfaces: slot.interfaces,
      pluginInfo: getPluginInfo(documentServicesAPI, slotComponentData),
      placement: {
        appDefinitionId,
        widgetId,
        slotId,
      },
    };
  },
  getWidgetSlot(
    documentServicesAPI: DocumentServicesObject,
    virtualSlotsPlaceholderRef: CompRef,
  ): WidgetSlot {
    const { widgetHostRef, slotName } = this.getVirtualSlotPlaceholderData(
      virtualSlotsPlaceholderRef,
    );
    const slots = this.getSlotsFromAppManifest(
      documentServicesAPI,
      widgetHostRef,
    );
    const slot = slots.find((s) => s.name === slotName);
    if (!slot) {
      throw new Error(`Slot not found for ${slotName}`);
    }
    return this.getWidgetSlotData(documentServicesAPI, widgetHostRef, slot);
  },
  async installWidgetPlugin(
    documentServicesAPI: DocumentServicesObject,
    virtualSlotPlaceholderRef: CompRef,
    widgetPointer: WidgetPointer,
  ): Promise<CompRef> {
    const appData = documentServicesAPI.tpa.app.getDataByAppDefId(
      widgetPointer.appDefinitionId,
    );
    const widgetComponent = await createWidgetPluginComponentDefinition(
      documentServicesAPI,
      widgetPointer,
      appData.applicationId!,
    );

    const { widgetHostRef, slotName } = this.getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const compRef: CompRef = documentServicesAPI.components.slots.populate(
      widgetHostRef,
      slotName,
      widgetComponent,
    );

    return compRef;
  },
  getWidgetHostRef(_: unknown, virtualSlotPlaceholderRef: CompRef): CompRef {
    const { widgetHostRef } = this.getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    return widgetHostRef;
  },
  uninstallWidgetPlugin(
    documentServicesAPI: DocumentServicesObject,
    virtualSlotPlaceholderRef: CompRef,
  ) {
    const { widgetHostRef, slotName } = this.getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    documentServicesAPI.components.slots.remove(widgetHostRef, slotName);
  },
  getWidgetPointer(
    documentServicesAPI: DocumentServicesObject,
    virtualSlotPlaceholderRef: CompRef,
  ): WidgetPointer | undefined {
    const { widgetHostRef, slotName } = this.getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const contentRef =
      documentServicesAPI.components.slots.getSlotsData(widgetHostRef)[
        slotName
      ];

    if (!contentRef) {
      return undefined;
    }

    const { appDefinitionId, widgetId } =
      documentServicesAPI.components.data.get(contentRef);

    return { appDefinitionId, widgetId };
  },
};
