import {
  DocumentServicesObject,
  Pointer,
  ScopePointer,
} from '@wix/document-services-types';
import { AppData, ComponentRef } from '@wix/editor-platform-sdk-types';

function getAppDefinitionId(data: any) {
  return data?.appDefinitionId ?? data?.applicationId;
}

function getAppDefinitionIdByComponentRef(
  documentServices: DocumentServicesObject,
  componentRef: ComponentRef,
): string {
  const componentData = documentServices.components.data.get(componentRef);

  // sometimes appDefinitionId comes in applicationId
  return getAppDefinitionId(componentData);
}

export function assertComponentBelongsToApplication(
  documentServices: DocumentServicesObject,
  componentRef: ComponentRef,
  appDefinitionId: string,
): void {
  const targetAppDefId = getAppDefinitionIdByComponentRef(
    documentServices,
    componentRef,
  );

  if (targetAppDefId !== appDefinitionId) {
    throw new Error(
      `Component ${componentRef.id} does not belong to the application ${appDefinitionId}. Cannot act on view mode for a component of another application.`,
    );
  }
}

export function getNestedWidgets(
  documentServices: DocumentServicesObject,
  appData: AppData,
  componentRef: ComponentRef,
) {
  const nestedWidgets = documentServices.components
    // @ts-expect-error for some reason types for options are missed
    .getChildren(componentRef, { fromDocument: true, recursive: true })
    .map((childCompRef) => ({
      compRef: childCompRef,
      compData: documentServices.components.data.get(childCompRef),
    }))
    .filter(({ compData }) => !!compData)
    .filter(
      ({ compData }) =>
        !!compData.appDefinitionId &&
        compData.appDefinitionId === appData.appDefinitionId,
    );

  return nestedWidgets
    .map(({ compRef }) => {
      const isRef =
        documentServices.components.refComponents.isReferredComponent(compRef);

      if (isRef) {
        const children =
          documentServices.deprecatedOldBadPerformanceApis.components.getChildren(
            compRef,
          );

        return children?.[0];
      } else {
        return compRef;
      }
    })
    .filter((compRef) => !!compRef);
}

export function getViewStatesByCompRef(
  documentServices: DocumentServicesObject,
  compRef: ComponentRef,
) {
  const stageData = documentServices.platform.controllers.getStageData(compRef);

  return stageData?.viewStates ?? {};
}

export const extractScopes = (
  pointer: Pointer,
  documentServicesAPI: DocumentServicesObject,
): ScopePointer[] => {
  const scopes: ScopePointer[] = [];

  const stack = [pointer];

  while (stack.length) {
    const current = stack.pop();
    if (!current) {
      continue;
    }
    const scope =
      documentServicesAPI.components.scopes.extractScopeFromPointer(current);

    if (scope) {
      scopes.push(scope);
      stack.push(scope);
    }
  }

  return scopes;
};
