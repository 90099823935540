import { DocumentServicesObject } from '@wix/document-services-types';
import { ManifestUrlResolver as AbstractManifestUrlResolver } from '@wix/editor-platform-manifest-services';
import { getBuilderDataByCompType, getManifestUrl } from './utils';
import {
  createEditorPlatformUrlResolverError,
  EditorPlatformUrlResolverErrorCode,
} from './errors';

export class ManifestUrlResolver implements AbstractManifestUrlResolver {
  private _manifestsUrls = new Map<string, string>();

  constructor(private readonly documentServicesAPI: DocumentServicesObject) {
    this.documentServicesAPI = documentServicesAPI;
  }

  public getManifestUrlByCompType(compType: string) {
    const { appDefinitionId, widgetId } = getBuilderDataByCompType(compType);
    const appData =
      this.documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);

    const manifestUrl = getManifestUrl(
      this.documentServicesAPI,
      widgetId,
      appData,
    );

    if (!manifestUrl) {
      throw createEditorPlatformUrlResolverError(
        EditorPlatformUrlResolverErrorCode.ManifestUrlNotFound,
      ).withComponentType(compType);
    }

    if (this._manifestsUrls.has(compType)) {
      return this._manifestsUrls.get(compType);
    }

    this._manifestsUrls.set(compType, manifestUrl);

    return manifestUrl;
  }
}
