import type {
  DefaultPanelOptions,
  BehaviorName,
} from '@wix/editor-platform-host-integration-apis';

const SETTINGS = 'settings';
const DASHBOARD = 'dashboard';
const PLATFORM_PANEL_OPTIONS: {
  [key in BehaviorName]: {
    panelOptions: DefaultPanelOptions;
  };
} = {
  [SETTINGS]: {
    panelOptions: {
      type: 'settings',
      title: 'Settings',
      helpId: '',
    },
  },
  [DASHBOARD]: {
    panelOptions: {
      type: 'dashboard',
      title: 'Dashboard',
      helpId: '',
    },
  },
};

export { PLATFORM_PANEL_OPTIONS, SETTINGS, DASHBOARD };
