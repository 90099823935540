import { HeightMode } from '@wix/ambassador-app-service-webapp/types';
import { WidgetSize } from '../../../../types/unifiedComponents';

export function chainPromisesAsync<T>(
  array: T[],
  functionToApply: (item: T) => Promise<any>,
) {
  return array.reduce(
    (previousPromise, currentItem) =>
      previousPromise.then(() => functionToApply(currentItem)),
    Promise.resolve(),
  );
}

export function getLayout(size?: WidgetSize) {
  return {
    height:
      size?.height?.heightMode === HeightMode.FIXED &&
      size?.height?.defaultHeight
        ? size?.height?.defaultHeight
        : 500,
    width: size?.width?.defaultWidth ?? 500,
  };
}
