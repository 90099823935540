import {
  IConsumerType,
  IHostType,
  IAPIVersions,
  IContext,
  IInitConfig,
} from '@wix/editor-platform-host-integration-apis';
import {
  onApiRequestedFromEventSource,
  onApiRequestedFromWorker,
} from '@wix/editor-platform-api-transport';
import {
  IMainSuperAPIFactory,
  createSuperAPIFactories,
  createPlatformAPI,
} from '@wix/editor-platform-sdk-implementation';

const PLATFORM_CONSUMER_TYPE_ATTRIBUTE = 'data-platform-consumer-type';

export const initV2 = <K extends IHostType>(config: IInitConfig<K>) => {
  const superAPIFactories = createSuperAPIFactories(
    config.hostType,
    config.hostAPI,
    config.documentServices,
  );

  const platformApiFactory = createPlatformAPIWithCache(
    config.hostType,
    superAPIFactories,
  );

  // subscribe on messages from iframes
  void onApiRequestedFromEventSource((event) => {
    const consumerType = getConsumerTypeFromEventSource(
      event.source as MessageEventSource,
    );

    if (!consumerType) {
      return null;
    }

    return (context: IContext, version: IAPIVersions) =>
      platformApiFactory(consumerType, context, version);
  });

  // subscribe on messages from workers
  config.workers?.forEach((config) => {
    void onApiRequestedFromWorker(config.worker, (_event) => {
      return (context: IContext, version: IAPIVersions) =>
        platformApiFactory(config.consumerType, context, version);
    });
  });
};

function createPlatformAPIWithCache<T extends IHostType>(
  hostType: T,
  superAPIFactories: IMainSuperAPIFactory<T>,
) {
  const cache = new Map<string, any>();
  return <V extends IAPIVersions>(
    consumer: IConsumerType,
    context: IContext,
    version: V,
  ) => {
    const cacheKey = `${context.appDefinitionId}-${consumer}-${version}`;
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }
    const platformApi = createPlatformAPI(
      context,
      hostType,
      consumer,
      version,
      superAPIFactories,
    );
    cache.set(cacheKey, platformApi);
    return platformApi;
  };
}

// This method searches for an iframe element the event been fired from
function findIframeByEventSource(
  eventSource: MessageEventSource,
): HTMLIFrameElement | undefined {
  const iframeNodes = document.getElementsByTagName('iframe');
  return Array.from(iframeNodes).find(
    (iframe) => iframe.contentWindow === eventSource,
  );
}

function getConsumerTypeFromEventSource(
  source: MessageEventSource,
): IConsumerType | null {
  const iframeElement = findIframeByEventSource(source);

  if (!iframeElement) {
    console.warn(
      `Unable to find an iframe associated with event source - ${source}`,
    );
    return null;
  }

  const consumerType = iframeElement.getAttribute(
    PLATFORM_CONSUMER_TYPE_ATTRIBUTE,
  );
  if (!consumerType) {
    console.warn(
      `Unable to obtain ${PLATFORM_CONSUMER_TYPE_ATTRIBUTE} from ${iframeElement}`,
    );
    return null;
  }

  return consumerType as IConsumerType;
}
