import { DocumentServicesObject } from '@wix/document-services-types';
import { IExperimentsApiAdapter } from '../../../utils/experimentsApiAdapter';
import { HostEventData } from './types';
import { getViewStateRegistryValues } from '../store';

type AppRefreshCompletedEventData = HostEventData<{
  appDefinitionId: string;
}>;

export const appRefreshCompleted = (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  event: HostEventData,
) => {
  const { appDefinitionId } = event as AppRefreshCompletedEventData;

  const viewStatesRegistryData = getViewStateRegistryValues(experimentsAPI);

  const appComponents = viewStatesRegistryData.filter(
    (viewState) => viewState.appData.appDefinitionId === appDefinitionId,
  );

  appComponents.forEach((viewState) => {
    (
      documentServicesAPI.platform as any
    ).livePreview.updateWidgetViewStateAfterRefresh({
      appDefinitionId,
      componentRef: viewState.componentRef,
      widgetViewState: { stateName: viewState.name, stateProps: {} },
    });
  });
};
