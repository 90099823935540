import * as appMarketService from '../utils/appMarketService';
import {
  ResolvedDependency,
  V2App,
  QueryAppsResponse,
  DependencyData,
} from '@wix/ambassador-app-service-webapp/http';
import {
  DependenciesDataDriver,
  InstallerAppsData,
  AppInstallOrigin,
} from '@wix/editor-platform-host-integration-apis/';
import { InstallInitiator } from '@wix/editor-platform-sdk-types/';
import { isGuid } from '../utils/guid';
import { DocumentServicesObject } from '@wix/document-services-types';

const filterActiveApp = (
  documentServices: DocumentServicesObject,
  dependenciesApps: ResolvedDependency[],
) =>
  dependenciesApps.filter(
    ({ appId }) => !documentServices.platform.isAppActive(appId!),
  );

const getAppIcon = (appMarketData: V2App): string | undefined => {
  return appMarketData?.market?.marketListing?.assetsMap?.screenshots
    ?.assets?.[0]?.url;
};

const buildAppData = (appMarketData: V2App) => ({
  label: appMarketData.name,
  appIconUrl: getAppIcon(appMarketData),
  info: appMarketData?.market?.marketListing?.basicInfo?.teaser,
  notify: false,
});

interface internalAppsMap {
  required: string[];
  optional: string[];
  provisionOnlyChild: Set<string>;
  childToNotify: Set<string>;
  parents: Set<string>;
  data: Omit<InstallerAppsData, 'appDefId'>;
}

const createInitialAppInternalData = (
  data: Omit<InstallerAppsData, 'appDefId'>,
) => ({
  required: [],
  optional: [],
  provisionOnlyChild: new Set<string>(),
  childToNotify: new Set<string>(),
  parents: new Set<string>(),
  data,
});

const updateParentsForRequiredApps = (
  appsMap: Record<string, internalAppsMap>,
  appId: string,
  shouldAdd: boolean,
  acc: string[] = [],
) => {
  appsMap[appId]?.required.forEach((child) => {
    if (shouldAdd) {
      appsMap[child]?.parents.add(appId);
    } else {
      appsMap[child]?.parents.delete(appId);
    }
    updateParentsForRequiredApps(appsMap, child, shouldAdd, acc);
    acc.push(child);
  });
  return acc;
};

const initDriver = (
  appsMap: Record<string, internalAppsMap>,
  appToInstallDefinitionId: string,
) => {
  const optionalApps = appsMap[appToInstallDefinitionId].optional;
  optionalApps.forEach((optionalApp) => {
    appsMap[optionalApp].data.toggle = false;
    appsMap[optionalApp].data.notify = true;
  });
  return buildAppsToInstall(appsMap, appToInstallDefinitionId);
};

const updateAppsNotifyStatus = (
  appsToInstall: string[],
  appsMap: Record<string, internalAppsMap>,
) => {
  appsToInstall.forEach((app) => {
    let shouldNotifyUser = false;
    const appData = appsMap[app];
    if (appData) {
      if (appData.data.toggle === undefined) {
        shouldNotifyUser = Array.from(appData.parents).some((parent) =>
          appsMap[parent].childToNotify.has(app),
        );
        appData.data.notify = shouldNotifyUser;
      }
    }
  });
};

const buildAppsToInstall = (
  appsMap: Record<string, internalAppsMap>,
  appToInstallDefinitionId: string,
) => {
  const appsToInstall = new Set([
    ...updateParentsForRequiredApps(appsMap, appToInstallDefinitionId, true),
  ]);
  const toggledApps = Object.keys(appsMap).filter(
    (optionalApp) => appsMap[optionalApp].data.toggle,
  );
  toggledApps.forEach((app) => {
    const reqApps = updateParentsForRequiredApps(appsMap, app, true);
    reqApps.forEach((reqApp) => appsToInstall.add(reqApp));
    appsToInstall.add(app);
  });
  appsToInstall.add(appToInstallDefinitionId);
  return Array.from(appsToInstall);
};

const initNonGuidApp = (
  appsMap: Record<string, internalAppsMap>,
  appToInstallDefinitionId: string,
) => {
  appsMap[appToInstallDefinitionId] = createInitialAppInternalData({
    notify: false,
  });
  return buildAppsToInstall(appsMap, appToInstallDefinitionId);
};

const createDependenciesDriver = async (
  documentServices: DocumentServicesObject,
  appToInstallDefinitionId: string,
  appVersion?: string,
): Promise<DependenciesDataDriver> => {
  const appsMap: Record<string, internalAppsMap> = {};
  let appsToInstall: string[] = [];

  if (!isGuid(appToInstallDefinitionId)) {
    // runtime apps special treatment - apps like wix-data
    appsToInstall = initNonGuidApp(appsMap, appToInstallDefinitionId);
  } else {
    // TODO think if we should put in cache
    let nonActiveDependenciesApp: ResolvedDependency[];
    let appsMarketData: QueryAppsResponse;
    try {
      nonActiveDependenciesApp = filterActiveApp(
        documentServices,
        await appMarketService.getAppDependencies(
          appToInstallDefinitionId,
          appVersion,
        ),
      );
      // TODO think if we should put in cache
      appsMarketData = await appMarketService.getAppsAppMarketData([
        { id: appToInstallDefinitionId, version: appVersion ?? 'latest' },
        ...(nonActiveDependenciesApp.map(({ appId }) => appId) as string[]),
      ]);
    } catch (e) {
      throw new Error(`appMarket call to dependencies api has failed. ${e}`);
    }

    if (!appsMarketData?.apps?.length) {
      throw new Error('app market query return empty payload');
    }

    appsMarketData.apps.forEach((appMarketData) => {
      appsMap[appMarketData.id as string] = createInitialAppInternalData(
        buildAppData(appMarketData),
      );
    });

    nonActiveDependenciesApp.forEach((dependencyApp) => {
      const appId = dependencyApp.appId!;

      if (dependencyApp.parentRelations) {
        dependencyApp.parentRelations.forEach((parent) => {
          const parentId = parent.appId!;
          if (parent.dependencyData) {
            const parentDependencyData: Partial<
              Record<DependencyData, boolean>
            > = {};

            parent.dependencyData.forEach(
              (data) => (parentDependencyData[data] = true),
            );

            if (parentDependencyData[DependencyData.IS_BUNDLE]) {
              appsMap[parentId].optional.push(appId);
            } else {
              appsMap[parentId].required.push(appId);
            }

            if (
              parentDependencyData[DependencyData.NOTIFY_USER_ON_DEPENDENCY]
            ) {
              appsMap[parentId].childToNotify.add(appId);
            }

            if (
              parentDependencyData[DependencyData.PROVISION_ONLY_INSTALLATION]
            ) {
              appsMap[parentId].provisionOnlyChild.add(appId);
            }
          } else {
            appsMap[parentId].required.push(appId);
          }
        });
      }
    });
    appsToInstall = initDriver(appsMap, appToInstallDefinitionId);
    updateAppsNotifyStatus(appsToInstall, appsMap);
  }

  const toggleOptionalDependency = (appId: string) => {
    if (appsMap[appId]?.data?.toggle === undefined) {
      throw new Error('it is possible to toggle only optional dependency apps');
    }
    appsMap[appId].data.toggle = !appsMap[appId].data.toggle;
    if (appsMap[appId].data.toggle) {
      appsMap[appId].parents.add(appToInstallDefinitionId);
    } else {
      appsMap[appId].parents.delete(appToInstallDefinitionId);
    }
    updateParentsForRequiredApps(appsMap, appId, appsMap[appId].data.toggle!);
    appsToInstall = buildAppsToInstall(appsMap, appToInstallDefinitionId);
    updateAppsNotifyStatus(appsToInstall, appsMap);
  };

  const getAppsToProvisionHeadless = () => {
    const appsToProvisionHeadless: Record<string, boolean> = {};
    appsToInstall.forEach((app) => {
      const parents = Array.from(appsMap[app]?.parents ?? []);
      appsToProvisionHeadless[app] =
        parents.length > 0 &&
        parents.every((parent) => appsMap[parent].provisionOnlyChild.has(app));
    });
    return appsToProvisionHeadless;
  };

  const getAppsToInstall = () => {
    const appsToProvisionHeadless = getAppsToProvisionHeadless();

    const dependencyOptions: Record<
      string,
      {
        headlessInstallation?: boolean;
        origin?: Partial<AppInstallOrigin>;
      }
    > = {};

    appsToInstall.forEach((app) => {
      if (app !== appToInstallDefinitionId) {
        dependencyOptions[app] = {
          origin: {
            initiator: InstallInitiator.Dependency_Service,
            info: {
              appDefinitionId: appToInstallDefinitionId,
            },
          },
        };
      }
      if (appsToProvisionHeadless[app]) {
        dependencyOptions[app] = {
          ...dependencyOptions[app],
          headlessInstallation: appsToProvisionHeadless[app],
        };
      }
    });

    return { appsToInstall, dependencyOptions };
  };

  const getData = () => {
    const dependenciesData: InstallerAppsData[] = [];
    Object.keys(appsMap).forEach((app) => {
      if (appsMap[app].data.toggle !== undefined || appsMap[app].parents.size) {
        dependenciesData.push({
          appDefId: app,
          ...appsMap[app].data,
        });
      }
    });
    return {
      dependenciesData,
      appToInstallName: appsMap[appToInstallDefinitionId].data.label!,
    };
  };

  return {
    getData,
    toggleOptionalDependency,
    getAppsToInstall,
  };
};

export { createDependenciesDriver };
