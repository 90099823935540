import { DocumentServicesObject } from '@wix/document-services-types';

const getSiteHeight = (ds: DocumentServicesObject) => ds.site.getHeight();

type SiteHeightChangeListener = (height: number) => void;

const createSiteHeightStore = () => {
  const listeners: SiteHeightChangeListener[] = [];
  let height: number = 0;

  return {
    set(siteHeight: number) {
      height = siteHeight;
      listeners.forEach((fn) => fn(height));
    },
    register(cb: SiteHeightChangeListener) {
      listeners.push(cb);
      cb(height);
    },
    get() {
      return height;
    },
  };
};

export const siteHeightStore = createSiteHeightStore();

export const updateSiteHeight = (ds: DocumentServicesObject) =>
  siteHeightStore.set(getSiteHeight(ds));
