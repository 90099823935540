import { merge } from 'lodash';
import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { canvasOverlayEnhancer } from './canvasOverlayEnhancer';
import { viewStateEnhancer } from './viewState';
import { toolsPanelEnhancer } from './toolsPanel';
import { pageReplaceEnhancer } from './pageReplace';
import { feedbackEnhancer } from './feedbackEnhancer';
import { classicDefaultImplementationEnhancer } from './classicDefaultImplementationEnhancer';

export const enhance: PlatformApiEnhancerFunction = (
  platformApiMethods,
  apis,
) => {
  const editorType = window.commonConfig?.host;
  return [
    viewStateEnhancer,
    feedbackEnhancer,
    canvasOverlayEnhancer,
    toolsPanelEnhancer,
    pageReplaceEnhancer,
    ...(editorType === 'EDITOR' ? [classicDefaultImplementationEnhancer] : []),
  ].reduce((enhancedPlatformApiMethods, applyEnhancer) => {
    merge(enhancedPlatformApiMethods, applyEnhancer(platformApiMethods, apis));
    return enhancedPlatformApiMethods;
  }, platformApiMethods);
};
