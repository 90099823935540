import {
  AppData,
  AppUnifiedComponentData,
  CompRef,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { UnifiedWidget } from '../../../types/unifiedComponents';
import { APP_SCHEMA_VERSIONS } from './installation/constants';
import { getComponentId } from '../../utils/component';

export const getComponentDataFromCSM = (
  documentServicesAPI: DocumentServicesObject,
  compRef: CompRef,
  appData?: AppData,
) => {
  const compData = documentServicesAPI.components.data.get(compRef);
  if (!appData) {
    if (compData?.appDefinitionId) {
      appData = documentServicesAPI.platform.getAppDataByAppDefId(
        compData.appDefinitionId,
      );
    }
  }

  if (
    // @ts-expect-error
    appData?.appFields?.newEditorSchemaVersion ===
    APP_SCHEMA_VERSIONS.EXTERNAL_UNIFIED_COMPONENT
  ) {
    return appData.components?.find(
      ({ componentId }) => compData.widgetId === componentId,
    )?.data as AppUnifiedComponentData;
  }

  return undefined;
};

export const getComponentIdentifiers = (
  documentServicesAPI: DocumentServicesObject,
  compRef: CompRef,
  appData?: AppData,
) => getComponentDataFromCSM(documentServicesAPI, compRef, appData)?.base;

export const isComponentEssential = (componentId: string, appData: AppData) => {
  const compDefinition = appData.components?.find(
    (comp) => comp.componentId === componentId,
  )?.data as AppUnifiedComponentData;

  return !!compDefinition?.installation?.base?.essential;
};

export const isComponentEssentialByRef = (
  documentServicesAPI: DocumentServicesObject,
  compRef: CompRef,
) => {
  const dsItem = documentServicesAPI.components.data.get(compRef);
  const appDefinitionId = dsItem?.appDefinitionId || dsItem?.applicationId;
  if (appDefinitionId) {
    const appData =
      documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);

    if (appData) {
      const componentId = getComponentId(dsItem);
      return !!componentId && isComponentEssential(componentId, appData);
    }
  }

  return false;
};

export const isPageEssential = (
  documentServicesAPI: DocumentServicesObject,
  pageId: string,
) => {
  const appDefinitionId =
    documentServicesAPI.pages.data.get(pageId)?.appDefinitionId;
  if (appDefinitionId) {
    const appData =
      documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);

    if (appData) {
      const pageComponents =
        documentServicesAPI.components.getAllComponents(pageId);
      const isEssentialPage = pageComponents.some((compRef) => {
        const componentId = getComponentId(
          documentServicesAPI.components.data.get(compRef),
        );
        return componentId && isComponentEssential(componentId, appData);
      });

      return isEssentialPage;
    }
  }
  return false;
};

export const isPageDuplicatable = (
  documentServicesAPI: DocumentServicesObject,
  pageId: string,
) => {
  const { appDefinitionId, tpaPageId } =
    documentServicesAPI.pages.data.get(pageId);
  if (appDefinitionId) {
    const appComponents =
      documentServicesAPI.platform.getAppDataByAppDefId(
        appDefinitionId,
      )?.components;
    if (appComponents) {
      const pageData = appComponents.find((component) => {
        const componentData = component?.data;
        return (
          (componentData as AppUnifiedComponentData)?.base?.id === tpaPageId
        );
      })?.data;
      return (
        (pageData as AppUnifiedComponentData)?.installation?.base
          ?.maxInstances !== 1
      );
    }
  }
  return true;
};

export const isWidgetStretchable = (
  documentServicesAPI: DocumentServicesObject,
  compRef: CompRef,
  appData?: AppData,
) => {
  const unifiedWidgetData = getComponentDataFromCSM(
    documentServicesAPI,
    compRef,
    appData,
  ) as UnifiedWidget;

  return unifiedWidgetData?.size?.width?.allowStretch;
};
