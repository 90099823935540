const getReplacerPageWixCode = (pageName: string, learnMoreUrl: string) => {
  const replacerPageWixCode = `// Get started with custom app pages: https://wix.to/nUVcdjt
  // Velo API Reference: https://www.wix.com/velo/reference/api-overview/introduction

  // For a step-by-step guide on creating a custom ${pageName}, see: ${learnMoreUrl}?origin=velo_ide

  import wixWindow from 'wix-window';

  $w.onReady(async function () {

      const pageData = await wixWindow.getAppPageData(); // Get the page's data object from the Wix app

      // Write code to initialize your UI elements, for example:
      // $w('#serviceName').text = pageData.service.name // Populate an element with the name of a Bookings service

      // Click "Run", or Preview your site, to execute your code

  });

  // Write code to navigate to the next page in the app's flow upon user action, in one of the following ways:

  /* Option 1: By specifying a URL, for example:

  import wixLocation from 'wix-location';

  $w('#nextPageButton').onClick(() => {

          const nextUrl = "/booking-form" + queryParams; https://wix.to/cOq2eNB
          wixLocation.to(nextUrl);
      })

  */

  /* Option 2: By calling a navigation API exposed by a Wix app, for example:

  import { navigate } from 'wix-stores-frontend';

  $w('#goToCartButton').onClick(() => {
              navigate.toCart(); // https://wix.to/rBvzCXj
      })

  */
    `;
  return replacerPageWixCode;
};

export { getReplacerPageWixCode };
