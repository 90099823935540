import { DocumentServicesObject } from '@wix/document-services-types';
import { EventType } from '@wix/editor-platform-sdk-types';
import { componentSelectionChangedHandler } from './componentSelectionChanged';
import { IExperimentsApiAdapter } from '../../../utils/experimentsApiAdapter';
import { HostEventData } from './types';
import { appRefreshCompleted } from './appRefreshCompleted';

export type EventData = {
  eventType: string;
  eventPayload?: object;
};

const getHostEventData = (event: string): HostEventData => {
  const emptyData: EventData = {
    eventType: '',
    eventPayload: {},
  };

  try {
    const data = JSON.parse(event) as { args?: EventData };

    const { eventType, eventPayload = {} } = data?.args ?? emptyData;

    return { eventType, ...eventPayload } as HostEventData;
  } catch {
    return {
      eventType: '',
    } as HostEventData;
  }
};

export const subscribeOnHostEvents = (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
) => {
  /**
   * In studio/responsive selection after app refresh works correctly,
   * so we do this trick for other editors or if host is not defined for some reason
   */
  // const host = window.commonConfig?.host;
  // const isEditorClassic = host === 'EDITOR' || !host;
  let shouldSkipSelectionChangedEvent = false;

  documentServicesAPI.platform.subscribeOnCustomAppEvents((e) => {
    const hostEvent = getHostEventData(e);
    const eventType = hostEvent.eventType;

    switch (eventType) {
      case EventType.componentSelectionChanged:
        if (shouldSkipSelectionChangedEvent) {
          shouldSkipSelectionChangedEvent = false;

          return;
        }

        componentSelectionChangedHandler(
          documentServicesAPI,
          experimentsAPI,
          hostEvent,
        );

        break;

      case EventType.appRefreshCompleted:
        appRefreshCompleted(documentServicesAPI, experimentsAPI, hostEvent);

        // if (isEditorClassic) {
        shouldSkipSelectionChangedEvent = true;
        // }

        break;
    }
  });
};
