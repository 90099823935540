import {
  initI18n,
  i18n as i18nType,
  I18nextProvider,
} from '@wix/wix-i18n-config';
import { getQueryParams } from '../utils';
import { TFunction } from '../ui/useTranslation';

// @ts-expect-error force type for better DX, so that it's easy to import { i18n } from './i18n.ts' and just use it normally.
let i18n: i18nType = null;

interface I18nInitSyncOptions {
  locale: string;
  wait?: false;
}

interface I18nInitAsyncOptions {
  locale: string;
  wait: true;
}

/**
 * Create i18next instance either sync with delayed initialization, or async with immediate initialization.
 *
 * @description
 * First option - use sync creation of i18next instance. Mainly for i18next react provider: it expects an i18n instance that can be in
 * process of initialization, provider prevents rendering until the instance is initialized. Also good for immediate kick off the initialization process upon script load.
 *
 * Second option - use async creation of i18next instance. This ensures that `t()` can be used right after `initialize()` call.
 *
 * @example <caption>Usage of sync creation</caption>
 * // see ./src/ui/providers/EditorPlatformI18nProvider.tsx
 * import { initialize } from './i18n.ts';
 *
 * initialize({ locale: 'en' });
 * t('my.key'); // "My key" or "my.key", not guaranteed during the same execution context due to a possible race condition
 *
 * // But some other function that is executed at some later point:
 * import { i18n, t } from './i18n.ts';
 * t('my.key'); // "My key"
 * i18n.t('my.key') // "My key"
 *
 * @example <caption>Usage of async creation</caption>
 * import { initialize, i18n, t } from './i18n.ts';
 *
 * await initialize({ locale: 'en', wait: true });
 * t('my.key'); // "My key"
 * i18n.t('my.key'); // "My key"
 */
export function initialize(options: I18nInitSyncOptions): i18nType;
export function initialize(options: I18nInitAsyncOptions): Promise<i18nType>;
export function initialize(
  options: I18nInitSyncOptions | I18nInitAsyncOptions,
): Promise<i18nType> | i18nType {
  if (i18n) {
    return i18n;
  }

  const { locale, wait = false } = options;
  const { debug } = getQueryParams();

  const instance = initI18n({
    disableAutoInit: true,
    locale,
    asyncMessagesLoader: (language) =>
      import(`../assets/locale/messages_${language}.json`),
  });

  instance.init({
    fallbackLng: 'en',
    debug: debug !== undefined,
  });

  if (wait) {
    return new Promise((resolve, reject) => {
      if (instance.isInitialized) {
        i18n = instance;
        return resolve(instance);
      }

      const timeoutId = setTimeout(() => {
        reject(new Error('Failed to initialize Editor Platform translations'));
      }, 5000);

      instance.on('initialized', () => {
        clearTimeout(timeoutId);
        i18n = instance;
        resolve(instance);
      });
    });
  }

  i18n = instance;

  return instance;
}

export const t: TFunction = (...args: Parameters<typeof i18n.t>): string => {
  if (i18n === null) {
    throw new Error(
      `i18next did not initialize yet. Did you forget to run 'initialize()' before using 't()'?`,
    );
  }

  return i18n.t(...args);
};

export const setLanguage = (locale: string) => {
  if (i18n === null) {
    throw new Error(
      `i18next did not initialize yet. Did you forget to run 'initialize()' before using 'setLanguage()'?`,
    );
  }

  const isProvidedLocaleSameAsInitialized = i18n.language === locale;

  function changeLanguageIfNeeded(): void {
    if (!isProvidedLocaleSameAsInitialized) {
      i18n.changeLanguage(locale);
    }
  }

  if (i18n.isInitialized) {
    changeLanguageIfNeeded();
  } else {
    i18n.on('initialized', () => {
      changeLanguageIfNeeded();
    });
  }
};

export { i18n, I18nextProvider };
