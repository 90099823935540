import { AppData, DocumentServicesObject } from '@wix/document-services-types';

const MANIFEST_BUILDER_SCRIPT = 'blocks-builder-manifest-generator';
const DATA_FIXER_SCRIPT = 'santa-data-fixer';
const MANIFEST_VERSION_TOKEN = '$MANIFEST_MODULE_VERSION$';
const DATA_FIXER_TOKEN = '$DATA_FIXER_VERSION$';

function resolveManifestUrlTokens(
  documentServicesAPI: DocumentServicesObject,
  url: string,
) {
  const manifestGeneratorModuleVersion =
    documentServicesAPI.generalInfo.getSiteAssetsScriptVersion(
      MANIFEST_BUILDER_SCRIPT,
    );
  const dfVersion =
    documentServicesAPI.generalInfo.getSiteAssetsScriptVersion(
      DATA_FIXER_SCRIPT,
    );

  return url
    .replace(MANIFEST_VERSION_TOKEN, manifestGeneratorModuleVersion)
    .replace(DATA_FIXER_TOKEN, dfVersion);
}

function getManifestUrlKey(widgetId: string) {
  return `${widgetId}_manifestUrl`;
}

function getManifestUrl(
  documentServicesAPI: DocumentServicesObject,
  widgetId: string,
  appData?: AppData,
) {
  const baseManifestUrl =
    appData?.appFields?.platform?.baseUrls?.[getManifestUrlKey(widgetId)];

  if (!baseManifestUrl) {
    return null;
  }

  return resolveManifestUrlTokens(documentServicesAPI, baseManifestUrl);
}

function getBuilderDataByCompType(compType: string) {
  const [, , appDefinitionIdAndWidgetId] = compType.split('.');
  const [appDefinitionId, widgetId] = appDefinitionIdAndWidgetId.split('_');

  if (!appDefinitionId || !widgetId) {
    throw new Error('Invalid builder component type');
  }

  return { appDefinitionId, widgetId };
}

function getManifestUrlByCompType(
  documentServicesAPI: DocumentServicesObject,
  compType: string,
) {
  const { appDefinitionId, widgetId } = getBuilderDataByCompType(compType);
  const appData =
    documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);

  return getManifestUrl(documentServicesAPI, widgetId, appData);
}

export { getManifestUrl, getBuilderDataByCompType, getManifestUrlByCompType };
