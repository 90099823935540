import {
  DocumentServicesObject,
  EditorClientSpecMapEntry,
} from '@wix/document-services-types';

const getHybridPendingApps = (documentServices: DocumentServicesObject) => {
  return documentServices.tpa.getPendingApps();
};

const getPremiumPendingApps = (documentServices: DocumentServicesObject) => {
  // @ts-expect-error
  return documentServices.tpa.getPremiumPendingApps();
};

const getPendingApps = (
  documentServices: DocumentServicesObject,
  predicate?: (app: EditorClientSpecMapEntry) => boolean,
): EditorClientSpecMapEntry[] => {
  const hybridPendingApps = getHybridPendingApps(documentServices);
  const premiumPendingApps = getPremiumPendingApps(documentServices);
  const allPending = [...hybridPendingApps, ...premiumPendingApps];
  return typeof predicate === 'function'
    ? allPending.filter(predicate)
    : allPending;
};

export { getPendingApps };
