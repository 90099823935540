import {
  AppData,
  ToolsMenuItemOptions,
  ToolsPanelOptions,
} from '@wix/editor-platform-sdk-types';
import {
  PlatformApiEnhancerFunction,
  PlatformApiMethods,
} from '../../../types/platformApi';
import {
  toolsEntryStore,
  ToolsEntryStoreApi,
} from '../../services/toolsEntryService';
import { UrlDecorator } from '../../utils/urlDecorator';

export const toolsPanelEnhancer: PlatformApiEnhancerFunction = (
  platformApiMethods,
  { urlDecorator },
) => {
  const { registerToolsPanel, unregisterToolsPanel } = createToolsPanelMethods(
    platformApiMethods,
    toolsEntryStore,
    urlDecorator,
  );
  return {
    editor: {
      registerToolsPanel,
      unregisterToolsPanel,
    },
  };
};

function createToolsPanelMethods(
  platformApiMethods: PlatformApiMethods,
  toolsEntryAPI: ToolsEntryStoreApi,
  urlDecorator: UrlDecorator,
) {
  function registerToolsPanel(
    appData: AppData,
    { title: toolsMenuItemTitle }: ToolsMenuItemOptions,
    {
      title: providedPanelTitle,
      url,
      width,
      height,
      initialPosition,
      initialData,
    }: ToolsPanelOptions,
  ): void {
    const { appDefinitionId, applicationId } = appData;
    if (!appDefinitionId) {
      throw new Error(`appDefinitionId is missing`);
    }

    toolsEntryAPI.set({
      appDefinitionId,
      toolsMenuItem: { title: toolsMenuItemTitle },
      onClick: async () => {
        await platformApiMethods.editor.openFloatingToolPanel(
          { appDefinitionId, applicationId },
          '',
          {
            title: providedPanelTitle || toolsMenuItemTitle,
            url: urlDecorator.decorateWithOrigin(url),
            width,
            height,
            initialPosition,
            initialData,
          },
        );
      },
    });
  }

  function unregisterToolsPanel(appData: AppData): void {
    const { appDefinitionId } = appData;
    if (!appDefinitionId) {
      throw new Error(`appDefinitionId is missing`);
    }
    toolsEntryAPI.remove(appDefinitionId);
  }

  return {
    registerToolsPanel,
    unregisterToolsPanel,
  };
}
