import { DocumentServicesObject } from '@wix/document-services-types';
import { reset } from './store';
import { subscribeOnHostEvents } from './events';
import { IExperimentsApiAdapter } from '../../utils/experimentsApiAdapter';

const VIEW_STATE_RESET_TRIGGERS = [
  // navigate between pages
  'pages.navigateTo',
  // Site Preview
  'documentMode.setComponentViewMode',
  // switch between DESKTOP and MOBILE
  'viewMode.set',
];

export const init = (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
) => {
  subscribeOnHostEvents(documentServicesAPI, experimentsAPI);

  documentServicesAPI.registerToSiteChanged((updates?: string[]) => {
    if (
      updates &&
      updates.length &&
      updates.some((update) => VIEW_STATE_RESET_TRIGGERS.includes(update))
    ) {
      reset(experimentsAPI);
    }
  });
};
