import { CompRef, DocumentServicesObject } from '@wix/document-services-types';
import {
  PluginInstallationParams,
  WidgetPointer,
} from '@wix/editor-platform-host-integration-apis';
import { ComponentRef } from '@wix/editor-platform-sdk-types';
import { PlatformContext } from '../../../types/platformApi';
import { hostApi } from './hostAPI/hostAPI';
import { PluginsHostApiContext } from '../../../types/widgetPlugins';

async function navigateAsync(
  documentServicesAPI: DocumentServicesObject,
  pageId: string,
) {
  const promisifiedNavigation = new Promise<void>((resolve) => {
    documentServicesAPI.pages.navigateTo(
      pageId,
      () => {
        resolve();
      },
      () => {
        resolve(); // If we couldn't navigate we still want to try to add the plugin
      },
    );
  });
  return promisifiedNavigation;
}

async function addWidgetPlugin(
  documentServicesAPI: DocumentServicesObject,
  slotComponentRef: ComponentRef,
  widgetRef: WidgetPointer,
  context: PluginsHostApiContext,
): Promise<CompRef> {
  const hostAPI = hostApi(documentServicesAPI, context);
  const slotInfo = hostAPI.getWidgetSlot(slotComponentRef);

  if (slotInfo?.pluginInfo !== undefined) {
    await hostAPI.uninstallWidgetPlugin(slotComponentRef);
  }

  return hostAPI.installWidgetPlugin(slotComponentRef, widgetRef);
}

function populate(
  documentServicesAPI: DocumentServicesObject,
  pluginInstallationParams: PluginInstallationParams,
  context: PluginsHostApiContext,
): Promise<CompRef> {
  return addWidgetPlugin(
    documentServicesAPI,
    pluginInstallationParams.slotComponentRef,
    pluginInstallationParams.widgetRef,
    context,
  );
}
export async function autoInstall(
  documentServicesAPI: DocumentServicesObject,
  pluginInstallationParams: PluginInstallationParams,
  context: PluginsHostApiContext,
): Promise<CompRef | null> {
  const pluginRef = await populate(
    documentServicesAPI,
    pluginInstallationParams,
    context,
  );

  return pluginRef;
}

export async function autoInstallWithNavigation(
  context: PlatformContext,
  documentServicesAPI: DocumentServicesObject,
  pluginInstallationParams: PluginInstallationParams,
  pluginsHostAPIContext: PluginsHostApiContext,
): Promise<CompRef | null> {
  await navigateAsync(documentServicesAPI, pluginInstallationParams.pageId);

  await documentServicesAPI.waitForChangesAppliedAsync();

  const pluginRef = await populate(
    documentServicesAPI,
    pluginInstallationParams,
    pluginsHostAPIContext,
  );

  await documentServicesAPI.waitForChangesAppliedAsync();

  await (documentServicesAPI.pages as any).scrollToComponent(pluginRef.id); // for some reason type is missing and its actually exists

  context.platformApiMethods.editor.selection.selectComponentByCompRef(
    null,
    '',
    { compsToSelect: [pluginRef] },
  );

  return pluginRef;
}
