import { PageRef, RoutersDefinition } from '@wix/document-services-types';
import { getPageRoleByPageId } from './pages';
import { PageRule } from '@wix/editor-platform-sdk-types';

function getVariantsByPageRole(config: string, pageRole: string): string[] {
  const roleVariations = JSON.parse(config)?.roleVariations || {};
  const ruleIds = roleVariations[pageRole] || [];
  const allRules = JSON.parse(config)?.rules || {};
  return ruleIds.map((id: string) => allRules[id].pageRole);
}

function getVariant(
  config: string,
  pages: RoutersDefinition['pages'],
  pageRef: PageRef,
) {
  const role = getPageRoleByPageId(pages, pageRef.id);
  if (role && JSON.parse(config)?.rules) {
    const rules: PageRule[] = Object.values(JSON.parse(config).rules);
    return !!rules.find((rule: PageRule) => rule.pageRole === role);
  }
  return false;
}

export { getVariantsByPageRole, getVariant };
