import { Shell } from 'repluggable';
import { ContributionPoints } from '@wix/editor-const';

/*
  Note: declared here and not imported since both editors declare this slot with different layers.
  it works since both editors expose the api under the same name and its defined public.
*/
export const WorspaceApiSlot = {
  name: 'Workspace API',
  public: true,
};

// Note: Classic & X have different implementation for Workspace API
interface IWorkspaceAPI {
  // Classic
  contributeStageComponent?: (contributeFn: contributeComponentFn) => void;
  // X
  contributeComponent?: (
    shell: Shell,
    contributionPoint: string,
    contributeFn: contributeComponentFn,
  ) => void;
}
type contributeComponentFn = () => JSX.Element;

export interface IWorkspaceApiAdapter {
  contributeStageComponent: (contributeFn: contributeComponentFn) => void;
}

export const createWorkspaceApi = (shell: Shell): IWorkspaceApiAdapter => {
  const workspaceAPI: IWorkspaceAPI = shell.getAPI(WorspaceApiSlot);
  return {
    contributeStageComponent: (contributeFn: contributeComponentFn) =>
      workspaceAPI.contributeStageComponent
        ? workspaceAPI.contributeStageComponent(contributeFn)
        : workspaceAPI.contributeComponent?.(
            shell,
            ContributionPoints.PreviewAddons,
            contributeFn,
          ) || createEmptyWorkspaceApi(),
  };
};

// empty implementation for ADI
export const createEmptyWorkspaceApi = (): IWorkspaceApiAdapter => ({
  contributeStageComponent: () => {},
});
