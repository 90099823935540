export const PAGE_REGIONS = {
  HEAD: 'HEAD',
  BODY: 'BODY',
  FOOTER: 'FOOTER',
};

export const BLOCKS_WIDGET_COMP_TYPE = 'STUDIO_WIDGET';

export const CUSTOM_ELEMENT_COMP_TYPE = 'CUSTOM_ELEMENT_WIDGET';

export const CODE_PACKAGE = 'CODE_PACKAGE';

export const BI_EVENT_IDS = {
  PLATFORM_INSTALLATION: 114,
  PROVISION_APPS: 115,
  NEW_COMPONENTS_INSTALL: 116,
  INSTALL_CODE_PACKAGES: 118,
  PLATFORM_RUN_SCRIPTS: 119,
  NAVIGATE_TO_DEV_CENTER: 120,
  PLATFORM_ERROR: 121,
};

export const APP_SCHEMA_VERSIONS = {
  EXTERNAL_UNIFIED_COMPONENT: 'externalUnifiedComponents',
};
