import { DocumentServicesObject } from '@wix/document-services-types';
import {
  InstallOptions,
  InstallCallbacks,
} from '@wix/editor-platform-host-integration-apis';
import { createDependenciesDriver } from './appDependenciesDriver';
import * as unifiedComponents from './unifiedComponents/installation/addApps';
import { PlatformContext } from '../../types/platformApi';
import { IExperimentsApiAdapter } from '../utils/experimentsApiAdapter';

export interface InstallAppWithInstallerBuilderOptions {
  origin: {
    type: string;
    initiator: string;
    info: {
      type: string;
    };
  };
  appVersion?: string;
  callback: (data: any) => void;
}

const installApps = (
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appDefinitionIds: string[],
  installOptions?: InstallOptions,
  callbacks?: InstallCallbacks,
) => {
  if (experimentsAPI.enabled('se_unifiedComponentsInstallation')) {
    return unifiedComponents.installApps(
      context,
      documentServices,
      appDefinitionIds,
      installOptions,
      callbacks,
    );
  }
  const { appsOptions, ...installationOptions } = installOptions || {};
  return documentServices.platform.addApps(appDefinitionIds, {
    ...appsOptions,
    ...installationOptions,
    ...callbacks,
  });
};

export { createDependenciesDriver, installApps };
