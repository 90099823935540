import { Shell, hot } from 'repluggable';
import { DocumentServicesAPI, ExperimentsAPI } from '@wix/editorx-core-api';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import { createEditorPlatformHostIntegrationAPI } from './createEditorPlatformHostIntegrationAPI';
import { WorspaceApiSlot } from './apis/utils/workspaceApiAdapter';

export const EditorPlatformHostIntegrationPackage = hot(module, [
  {
    name: 'EDITOR_PLATFORM_HOST_INTEGRATION_API',

    layer: 'DATA_SERVICE',

    getDependencyAPIs() {
      return [DocumentServicesAPI, WorspaceApiSlot, ExperimentsAPI];
    },

    declareAPIs() {
      return [EditorPlatformHostIntegrationAPI];
    },

    attach(shell: Shell) {
      shell.contributeAPI(EditorPlatformHostIntegrationAPI, () =>
        createEditorPlatformHostIntegrationAPI(shell),
      );
    },
  },
]);
