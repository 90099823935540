import { intersection } from 'lodash';
import { DocumentServicesObject } from '@wix/document-services-types';
import { ComponentRef, EventType } from '@wix/editor-platform-sdk-types';
import { resetWithPredicate } from '../store';
import { RESET_VIEW_STATE_EXPERIMENT } from '../constants';
import { extractScopes } from '../utils';
import { IExperimentsApiAdapter } from '../../../utils/experimentsApiAdapter';
import { HostEventData } from './types';

const isClickedInsideWidget = (
  documentServicesAPI: DocumentServicesObject,
  registryRef: ComponentRef,
  selectedRefs: ComponentRef[],
) => {
  if (selectedRefs.some((ref) => ref.id === registryRef.id)) {
    return true;
  }

  const nestedWidgets = documentServicesAPI.components
    // @ts-expect-error for some reason types for options are missed
    .getChildren(registryRef, { fromDocument: true, recursive: true });

  const nestedWidgetsIds = new Set([
    registryRef.id,
    ...nestedWidgets.map((el) => el.id),
  ]);

  const registryRefScope =
    documentServicesAPI.components.scopes.extractScopeFromPointer(registryRef);

  return selectedRefs.some((ref) => {
    if (nestedWidgetsIds.has(ref.id) || ref.id === registryRefScope?.id) {
      return true;
    }

    const refScopesIds = extractScopes(ref, documentServicesAPI).map(
      ({ id }) => id,
    );

    if (!refScopesIds.length) {
      return false;
    }

    return (
      intersection(refScopesIds, Array.from(nestedWidgetsIds)).length > 0 ||
      refScopesIds.includes(registryRefScope?.id!)
    );
  });
};

type ComponentSelectionChangedEventData = HostEventData<{
  componentRefs: ComponentRef[];
}>;

export const componentSelectionChangedHandler = (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  event: HostEventData,
) => {
  const { componentRefs, eventType } =
    event as ComponentSelectionChangedEventData;

  if (
    !componentRefs?.length ||
    !eventType ||
    eventType !== EventType.componentSelectionChanged
  ) {
    return;
  }

  if (!experimentsAPI.enabled(RESET_VIEW_STATE_EXPERIMENT)) {
    return;
  }

  resetWithPredicate(
    documentServicesAPI,
    experimentsAPI,
    ({ componentRef, state, initialState }) => {
      if (state === initialState) {
        return false;
      }

      if (
        isClickedInsideWidget(documentServicesAPI, componentRef, componentRefs)
      ) {
        return false;
      }

      return true;
    },
  );
};
