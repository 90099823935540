import type {
  EditorClientSpecMapEntry,
  ComponentInCsm,
} from '@wix/document-services-types';

const getAppComponents = (app: EditorClientSpecMapEntry): ComponentInCsm[] =>
  app?.components || [];

const isPlatformType = (app: EditorClientSpecMapEntry): boolean =>
  Boolean(app?.appFields?.platform) ||
  getAppComponents(app).some((comp) => comp.type === 'PLATFORM');

const isWidgetType = (app: EditorClientSpecMapEntry): boolean =>
  Object.values(app?.widgets || {}).some(
    (widget) => !widget.appPage && !!widget.default,
  );

const isSectionType = (app: EditorClientSpecMapEntry): boolean =>
  Object.values(app?.widgets || {}).some(
    (widget) => !!widget.appPage && !widget.appPage.hidden,
  );

const isBuilderType = (app: EditorClientSpecMapEntry): boolean =>
  getAppComponents(app).some((comp) => comp.type === 'STUDIO');

const isHeadlessInstallation = (app: EditorClientSpecMapEntry) => {
  const components = getAppComponents(app);
  return (
    app?.appFields?.platform?.platformOnly ||
    components.find(
      (comp) => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM',
    )?.data?.platformOnly
  );
};

const getAppType = function (app: EditorClientSpecMapEntry): string {
  if (isHeadlessInstallation(app)) {
    return 'platformOnly';
  } else if (isSectionType(app)) {
    return 'tpaSection';
  } else if (isBuilderType(app)) {
    return 'platformOnly';
  } else if (isWidgetType(app)) {
    return 'tpaWidget';
  } else if (isPlatformType(app)) {
    return 'platformOnly';
  }
  return 'tpaWidget';
};

export { getAppType, isBuilderType };
