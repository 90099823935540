import {
  ICanvasOverlay,
  ICanvasOverlayCallback,
} from '../../../types/canvasOverlay';

const createOverlayStore = () => {
  const overlays: ICanvasOverlay[] = [];
  const listeners: ICanvasOverlayCallback[] = [];
  return {
    add(overlay: ICanvasOverlay): void {
      overlays.push(overlay);
      listeners.forEach((cb) => cb([...overlays]));
    },
    remove(appDefinitionId: string): void {
      const index = overlays.findIndex(
        (overlay) => overlay.appDefinitionId === appDefinitionId,
      );
      if (index > -1) {
        overlays.splice(index, 1);
        listeners.forEach((cb) => cb([...overlays]));
      }
    },
    get(): ICanvasOverlay[] {
      return [...overlays];
    },
    has(appDefinitionId: string): boolean {
      return !!overlays.find(
        (overlay) => overlay.appDefinitionId === appDefinitionId,
      );
    },
    register(cb: ICanvasOverlayCallback): void {
      listeners.push(cb);
      cb([...overlays]);
    },
    hideAll(): void {
      overlays.forEach((_overlay, index, arr) => {
        arr[index].visible = false;
      });
      listeners.forEach((cb) => cb([...overlays]));
    },
    unhideAll(): void {
      overlays.forEach((_overlay, index, arr) => {
        arr[index].visible = true;
      });
      listeners.forEach((cb) => cb([...overlays]));
    },
  };
};

export const overlayStore = createOverlayStore();
