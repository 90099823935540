import { DocumentServicesObject } from '@wix/document-services-types';
import {
  ToolsEntryPayload,
  ToolsEntryEvent,
  ToolsEntryEventListener,
} from '@wix/editor-platform-host-integration-apis';

export interface ToolsEntryStoreApi {
  register(listener: ToolsEntryEventListener): void;
  unregister(listener: ToolsEntryEventListener): void;
  get(appDefinitionId: string): ToolsEntryPayload | undefined;
  getAll(): ToolsEntryPayload[];
  set(payload: ToolsEntryPayload): void;
  remove(appDefinitionId: string): void;
}

const store = new Map<string, ToolsEntryPayload>();

const createStore = (): ToolsEntryStoreApi => {
  const listeners = new Set<ToolsEntryEventListener>();

  const dispatchEvent = (event: ToolsEntryEvent) => {
    listeners.forEach((listener) => {
      listener(event);
    });
  };

  return {
    register(listener: ToolsEntryEventListener): void {
      listeners.add(listener);
    },

    unregister(listener: ToolsEntryEventListener): void {
      listeners.delete(listener);
    },

    get(appDefinitionId: string) {
      return store.get(appDefinitionId);
    },

    getAll(): ToolsEntryPayload[] {
      return Array.from(store.values());
    },

    set(payload: ToolsEntryPayload): void {
      const { appDefinitionId } = payload;

      if (store.has(appDefinitionId)) {
        throw new Error(
          `Application ${appDefinitionId} has already added it's tools panel entry`,
        );
      }

      store.set(appDefinitionId, payload);
      dispatchEvent({
        type: 'add',
        payload,
      });
    },

    remove(appDefinitionId: string): void {
      const payload = store.get(appDefinitionId);
      if (!payload) {
        return;
      }
      store.delete(appDefinitionId);
      dispatchEvent({
        type: 'remove',
        payload,
      });
    },
  };
};

export const toolsEntryStore = createStore();

export const init = (documentServicesAPI: DocumentServicesObject) => {
  documentServicesAPI.platform.registerToAppRemoved(
    (appDefinitionId: string) => {
      toolsEntryStore.remove(appDefinitionId);
    },
  );
};
