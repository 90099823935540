import { DocumentServicesObject } from '@wix/document-services-types';
import {
  createFeedbackLoopFromStructure,
  FeedbackLoop,
  IFeedbackFormStructure,
} from '@wix/feedback-loop';

const state: {
  form?: FeedbackLoop;
} = {};
export const openFeedbackForm = async (
  documentServices: DocumentServicesObject,
  appDefId: string,
  company: string,
  campaignId: string,
  structure: IFeedbackFormStructure,
) => {
  if (state.form) {
    return;
  }

  const form = createFeedbackLoopFromStructure(
    {
      appDefId,
      uuid: documentServices.generalInfo.getUserInfo().userId,
      editorType: window.commonConfig?.host,
      msid: documentServices.generalInfo.getMetaSiteId(),
      esi: window.editorModel?.editorSessionId,
      language: documentServices.generalInfo.getLanguage(),
      company,
      campaignId,
    },
    structure,
  );

  state.form = form;

  try {
    const data = await form.open();
    // TODO: integrate with form close reasons
    state.form = undefined;
    return data;
  } catch (e) {
    state.form = undefined;
    throw e;
  }
};

export const closeFeedbackForm = () => {
  state.form?.close();
  state.form = undefined;
};

const FEEDBACK_LOOP_HIDE_TRIGGERS = [
  // Site Preview
  'documentMode.setComponentViewMode',
];

export const init = (documentServicesAPI: DocumentServicesObject) => {
  documentServicesAPI.registerToSiteChanged((updates?: string[]) => {
    if (
      updates &&
      updates.length &&
      updates.some((update) => FEEDBACK_LOOP_HIDE_TRIGGERS.includes(update))
    ) {
      if (
        documentServicesAPI.documentMode.getComponentViewMode() === 'preview'
      ) {
        state.form?.hide();
      } else {
        state.form?.show();
      }
    }
  });
};
