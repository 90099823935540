const GUID_FORMAT = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
const guidRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

function randomizeChar(c: string) {
  const r = (Math.random() * 16) | 0;
  const v = c === 'x' ? r : (r & 0x3) | 0x8;
  return v.toString(16);
}

function getGUID() {
  /**
   * This is the same getGUID algorithm as in old client - we need this for BI, to keep a GUID in the normal GUID format
   * originally from http://stackoverflow.com/a/2117523
   * */
  return GUID_FORMAT.replace(/[xy]/g, randomizeChar);
}

function isGuid(value: string) {
  return guidRegex.test(value);
}

export { getGUID, isGuid };
