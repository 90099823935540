import { ExtendedPlatformContext } from '../../../../types/platformApi';
import { getAddUnifiedComponentsMethods } from './addComponents/addUnifiedComponents';
import { chainPromisesAsync } from './utils';
import { PageRef } from '@wix/document-services-types';
import {
  createUnifiedComponentsInstallationError,
  UnifiedComponentsInstallationErrorCode as ErrorType,
} from './errors';
import {
  AppData,
  UnifiedComponentsCollection,
  UnifiedPage,
  UnifiedWidget,
} from '../../../../types/unifiedComponents';
import { BIReporter } from './bi';

export function installUnifiedComponents(
  context: ExtendedPlatformContext,
  biReporter: BIReporter,
  appData: AppData,
  collection: UnifiedComponentsCollection,
) {
  const homePage = context.documentServices.pages.getReference(
    context.documentServices.homePage.get(),
  ) as PageRef;
  homePage.pageId = homePage.id;
  const { pages, widgets } = collection;
  const components_list = Object.keys(collection.pages).concat(
    Object.keys(collection.widgets),
  );
  const { addPage, addWidget } = getAddUnifiedComponentsMethods(
    context,
    appData,
  );

  function installUnifiedPages() {
    const addSinglePage = ([pageGuid, page]: [string, UnifiedPage]) =>
      new Promise<PageRef | undefined>((resolve, reject) =>
        addPage(page, widgets)
          .then(resolve)
          .catch((error) =>
            reject(
              createUnifiedComponentsInstallationError(
                ErrorType.addingUnifiedComponentFailed,
                `Failed to add '${page.base?.name}' page component`,
              )
                .withAppDefIds(appData.appDefinitionId)
                .withComponentGUID(pageGuid, 'unified-page')
                .withParentError(error),
            ),
          ),
      );

    return chainPromisesAsync(Object.entries(pages), addSinglePage);
  }

  function addSingleWidget(widget: UnifiedWidget) {
    return new Promise<PageRef | void>((resolve, reject) =>
      addWidget(homePage, widget, widget.installation?.widget?.defaultPreset)
        .then(resolve)
        .catch((error) =>
          reject(
            createUnifiedComponentsInstallationError(
              ErrorType.addingUnifiedComponentFailed,
              `Failed to add '${widget?.base?.name}' widget component`,
            )
              .withAppDefIds(appData.appDefinitionId)
              .withComponentGUID(widget.widgetId, 'unified-widget')
              .withParentError(error),
          ),
        ),
    );
  }

  function installUnifiedWidgets() {
    return new Promise<void>((resolve, reject) => {
      const widgetsArray = Object.values(widgets);
      if (widgetsArray.length === 0) {
        return resolve();
      }

      chainPromisesAsync(widgetsArray, addSingleWidget)
        .then(resolve)
        .catch(reject);
    });
  }

  return biReporter.withBI(
    'NEW_COMPONENTS_INSTALL',
    {
      components_list,
      numberInstalledComponents: 0,
      appDefinitionId: appData.appDefinitionId,
    },
    function addUnifiedComponentsToStage() {
      return new Promise<void>((resolve, reject) =>
        installUnifiedPages()
          .then(installUnifiedWidgets)
          .then(resolve)
          .catch(reject),
      );
    },
  );
}
