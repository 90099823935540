import { InitAppOptions } from '@wix/editor-platform-host-integration-apis';
import { getUserInfo } from '@wix/toolbelt';

interface KnownEditorQueryParams {
  metaSiteId?: string;
}

type QueryParams = KnownEditorQueryParams & {
  [K in string]?: string;
};

export const getQueryParams = (): QueryParams => {
  return Object.fromEntries(
    new URL(window.location.href).searchParams.entries(),
  );
};

export const getEditorInfo = () => {
  return window.commonConfig;
};

export const getEditorSessionId = () => {
  return window?.editorModel?.editorSessionId;
};

export const getMetaSiteId = () => {
  const params = getQueryParams();
  return params.metaSiteId!;
};

export const getUserId = () => {
  return getUserInfo().id;
};

export const getHostTypeFromInitOptions = (
  options?: InitAppOptions,
): string => {
  if (options?.origin.type === 'CLASSIC') {
    return 'editor';
  }

  if (options?.origin.type === 'RESPONSIVE') {
    return 'editor_x';
  }

  return '';
};
