export const builderServicesPact = {
  manifest: {
    behaviors: {
      getValueFromManifest: () => false,
    },
    style: {
      getValueFromManifest: () => false,
    },
    get: {
      rootManifestData: () => null,
      styleDefinition: () => null,
    },
    internal: {
      loadAndResolveManifestsByCompTypes: () => {},
    },
    isBuilderComponentType: () => false,
  },
  panels: {
    getDesignPanel: () => null,
  },
  [Symbol.for('pactBuilderServices')]: true,
};
