import { ExtendedPlatformContext } from '../../../../../types/platformApi';
import { AppData, UnifiedWidget } from '../../../../../types/unifiedComponents';
import {
  AddUnifiedComponentsErrorCode as ErrorCode,
  createAddUnifiedComponentsError,
} from '../errors';
import { getLayout } from '../utils';
import {
  CompRef,
  CompLayout,
  ComponentLayoutObject,
} from '@wix/document-services-types';

const TOKEN = 'unified-components-installation';

const createLayoutAccordingToEditorType = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  if (window.commonConfig?.host?.toLowerCase() === 'studio') {
    const layouts: Partial<ComponentLayoutObject> = {
      // responsive layout
      componentLayout: {
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: height,
        },
        width: {
          type: 'px',
          value: width,
        },
        maxWidth: {
          type: 'percentage',
          value: 100,
        },
      },
      itemLayout: {
        id: 'itemLayout',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        margins: {
          left: {
            type: 'percentage',
            value: 0,
          },
          right: {
            type: 'percentage',
            value: 0,
          },
          top: {
            type: 'percentage',
            value: 0,
          },
          bottom: {
            type: 'percentage',
            value: 0,
          },
        },
        justifySelf: 'center',
        alignSelf: 'center',
      },
    };
    return {
      layouts,
    };
  }
  const layout: Partial<CompLayout> = {
    // classic layout
    fixedPosition: true,
    x: 0,
    y: 0,
    width,
    height,
  };
  return {
    layout,
  };
};

const buildDefaultCustomElement = (
  layout: { width: number; height: number },
  {
    appDefinitionId,
    widgetId,
  }: {
    appDefinitionId: AppData['appDefinitionId'];
    widgetId: UnifiedWidget['widgetId'];
  },
) => ({
  componentType: 'wixui.CustomElementComponent',
  data: {
    appDefinitionId,
    widgetId,
    type: 'CustomElement',
    shouldReload: false,
    initialAttributes: '{}',
  },
  ...createLayoutAccordingToEditorType(layout),
  config: {},
});

export async function addCustomElement(
  context: ExtendedPlatformContext,
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  containerRef: CompRef,
): Promise<CompRef> {
  const { widgetId, size } = widgetDefinition;
  const layout = getLayout(size);

  function stretchIfNeeded(compRef: CompRef, reject: (reason?: any) => void) {
    if (size?.width?.stretchByDefault) {
      context.platformApiMethods.document.components
        .setFullWidth(appData, TOKEN, {
          componentRef: compRef,
          fullWidth: true,
        })
        .catch((error: Error) => {
          reject(
            createAddUnifiedComponentsError(
              ErrorCode.setWidgetFullWidthFailed,
              'Could not set widget to full width',
            )
              .withUserComponentId(widgetId, 'unified-widget')
              .withParentError(error),
          );
        });
    }
  }

  return new Promise<CompRef>((resolve, reject) => {
    context.platformApiMethods.document.components
      .add(appData, TOKEN, {
        componentDefinition: buildDefaultCustomElement(layout, {
          appDefinitionId: appData.appDefinitionId,
          widgetId,
        }),
        pageRef: containerRef,
      })
      .then((compRef: CompRef) => {
        context.documentServices.waitForChangesApplied(() => {
          stretchIfNeeded(compRef, reject);
          resolve(compRef);
        });
      })
      .catch((error: Error) => {
        reject(
          createAddUnifiedComponentsError(
            ErrorCode.addingCustomElementWidgetFailed,
            'Could not add custom element widget',
          )
            .withUserComponentId(widgetId, 'unified-widget')
            .withParentError(error),
        );
      });
  });
}
