import { DocumentServicesObject } from '@wix/document-services-types';
import {
  EditorPointer,
  ManifestServices,
} from '@wix/editor-platform-manifest-services';

import { handleErrors } from '../../utils/builderUtils';
import { ManifestUrlResolver } from '../manifestUrlResolverService';
import { initManifestServices } from './manifestServices';
import { loadInitialDataFromEERegistry } from './editorElementsRegistry';
import { IExperimentsApiAdapter } from './types';
import { PanelsStore } from './panelsStore';
import { builderServicesPact } from './builderServicesPact';

export function initBuilderServices(
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  isResponsiveEditor: boolean,
) {
  if (
    !experimentsAPI.enabled('specs.responsive-editor.enableManifestServices') ||
    !isResponsiveEditor
  ) {
    return builderServicesPact;
  }

  const panelsStore = new PanelsStore();
  const URLResolver = new ManifestUrlResolver(documentServicesAPI);

  const manifestServices = new ManifestServices(URLResolver);

  const initialLoad = loadInitialDataFromEERegistry(
    window.serviceTopology,
    experimentsAPI,
    {
      setManifest: manifestServices.setManifest,
      setDesignPanel: panelsStore.setPanel('design'),
    },
  ).catch(handleErrors);

  manifestServices.setInitialLoad(initialLoad);

  const manifestServicesAPI = initManifestServices(
    documentServicesAPI,
    manifestServices,
  );

  return {
    manifest: manifestServicesAPI,
    panels: {
      getDesignPanel: (editorPointer: EditorPointer) => {
        const compType =
          manifestServicesAPI.internal.getCompType(editorPointer);
        return panelsStore.getPanel(compType as string, 'design');
      },
    },
  };
}
