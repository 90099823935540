import { CompRef, DocumentServicesObject } from '@wix/document-services-types';
import { ManifestServices } from '@wix/editor-platform-manifest-services';
import {
  createBuildPathsFn,
  findBuilderComponentTypes,
  getCompTypesOnCurrentPage,
  handleErrors,
  isBuilderComponentType,
} from '../../utils/builderUtils';
import { EventType } from '@wix/editor-platform-sdk-types';

type Event = {
  type: EventType;
  args: {
    eventPayload: {
      compType: string;
      compRef: CompRef;
      appDefinitionId: string;
    };
  };
};

function createManifestLoadListeners(
  documentServicesAPI: DocumentServicesObject,
  manifestServices: ManifestServices,
  buildPaths: ReturnType<typeof createBuildPathsFn>,
) {
  function loadAndResolveManifestsByCompTypes({
    compTypes,
    customBuildPaths,
  }: {
    compTypes: string[];
    customBuildPaths?: typeof buildPaths;
  }): void {
    manifestServices
      .loadAndResolveManifestsByCompTypes(compTypes)
      .then(customBuildPaths ?? buildPaths)
      .catch(handleErrors);
  }

  function loadAndResolveManifestsByCompTypesOnCurrentPage(): void {
    const compTypes = getCompTypesOnCurrentPage(documentServicesAPI);

    loadAndResolveManifestsByCompTypes({ compTypes });
  }

  function handleNavigateToPage(updates?: string[]): void {
    if (updates?.length && updates?.includes('pages.navigateTo')) {
      loadAndResolveManifestsByCompTypesOnCurrentPage();
    }
  }

  function handleCustomAppEvents(event: Event): void {
    if (typeof event === 'string') {
      try {
        event = JSON.parse(event);
      } catch (e) {
        // do nothing
      }
    }

    if (event.type === EventType.componentAddedToStage) {
      const compType = event.args.eventPayload.compType;
      const compRef = event.args.eventPayload.compRef;

      if (!isBuilderComponentType(compType)) {
        return;
      }

      loadAndResolveManifestsByCompTypes({
        compTypes: [compType],
        customBuildPaths: (loadedCompTypes) =>
          buildPaths(loadedCompTypes, { compRef, compType }),
      });
    } else if (event.type === EventType.appUpdateCompleted) {
      const appDefinitionId = event.args.eventPayload.appDefinitionId;
      const appData =
        documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);
      const builderComponentTypes = findBuilderComponentTypes(appData);

      manifestServices
        .forceLoadAndResolveManifestsByCompTypes(builderComponentTypes)
        .then(buildPaths)
        .catch(handleErrors);
    }
  }

  return {
    handleNavigateToPage,
    handleCustomAppEvents,
  };
}

export { createManifestLoadListeners };
