import {
  factory as biLoggerFactory,
  Logger,
} from '@wix/fe-essentials-editor/bi';

let loggerInstance: Logger;

const BI_PLATFORM_HOST_INTEGRATION_ENDPOINT = 'editor-platform';
const BI_PLATFORM_HOST_INTEGRATION_SRC = 164;
export const PLATFORM_INIT = 122;
export const PLATFORM_ERROR = 121;

export function createBiLogger({
  userId,
  metaSiteId,
  editorType,
  editorSessionId,
  sdkInfo,
  endpoint = BI_PLATFORM_HOST_INTEGRATION_ENDPOINT,
  src = BI_PLATFORM_HOST_INTEGRATION_SRC,
}: {
  userId: string;
  metaSiteId: string;
  editorSessionId: string;
  editorType?: string;
  sdkInfo?: { sdkVersion?: string };
  endpoint?: string;
  src?: number;
}) {
  loggerInstance = biLoggerFactory({
    endpoint,
  })
    .updateDefaults({
      src,
      msid: metaSiteId,
      uuid: userId,
      esi: editorSessionId,
      sdkVersion: sdkInfo?.sdkVersion,
      editorType,
    })
    .logger();

  return loggerInstance;
}

export function reportBi(evid: number, event: Record<string, any>) {
  if (loggerInstance) {
    const eventParams = { ...event, evid };
    loggerInstance.log(eventParams);
  }
}
