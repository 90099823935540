import React from 'react';

import {
  ICanvasOverlay,
  ICanvasOverlayCallback,
} from '../../types/canvasOverlay';
import { CanvasOverlay } from './canvasOverlay';

interface ICanvasOverlayContainerProps {
  registerForOverlayUpdates: (cb: ICanvasOverlayCallback) => void;
  registerForSiteHeightUpdates: (cb: (height: number) => void) => void;
}

export const CanvasOverlayContainer: React.FC<ICanvasOverlayContainerProps> = (
  props: ICanvasOverlayContainerProps,
) => {
  const { registerForOverlayUpdates, registerForSiteHeightUpdates } = props;
  const [overlays, setOverlays] = React.useState<ICanvasOverlay[]>([]);
  const [siteHeight, setSiteHeight] = React.useState<number>(0);

  React.useEffect(() => {
    registerForOverlayUpdates((_overlays: ICanvasOverlay[]) => {
      setOverlays(_overlays);
    });
  }, [registerForOverlayUpdates]);

  React.useEffect(() => {
    registerForSiteHeightUpdates((height) => {
      setSiteHeight(height);
    });
  }, [registerForSiteHeightUpdates]);

  return (
    <div
      data-aid="canvas-overlay-container"
      style={{
        pointerEvents: 'none',
        height: siteHeight,
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
      }}
    >
      {overlays.map(({ appDefinitionId, url, visible }) => (
        <CanvasOverlay
          key={`${appDefinitionId}-overlay`}
          appDefinitionId={appDefinitionId}
          visible={visible}
          url={url}
        />
      ))}
    </div>
  );
};

export const getCanvasOverlayContainer = (
  registerForOverlaysUpdates: (cb: ICanvasOverlayCallback) => void,
  registerForSiteHeightUpdates: (cb: (height: number) => void) => void,
) => (
  <CanvasOverlayContainer
    registerForOverlayUpdates={registerForOverlaysUpdates}
    registerForSiteHeightUpdates={registerForSiteHeightUpdates}
  />
);
