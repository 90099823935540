import { CompRef, DocumentServicesObject } from '@wix/document-services-types';
import { WidgetPointer, WidgetSlot } from '../../../../types/widgetPlugins';
import { ErrorReporter } from '@wix/editor-error-reporter';
import {
  createWidgetPluginComponentDefinition,
  defaultSlotName,
} from '../utils/pluginUtils';

export const blocksHostAPI = {
  getWidgetSlot(
    documentServicesAPI: DocumentServicesObject,
    slotCompRef: CompRef,
  ): WidgetSlot {
    const hostRef = this.getWidgetHostRef(documentServicesAPI, slotCompRef);
    const slots = this.getSlotsFromDm(documentServicesAPI, hostRef);
    const slot = slots.find((s) => s.compRef.id === slotCompRef.id);
    if (!slot) {
      throw new Error(`Slot not found for ${slotCompRef.id}`);
    }
    return {
      ...slot,
      placement: this.getPlacement(documentServicesAPI, slot),
    };
  },
  getPlacement(
    documentServicesAPI: DocumentServicesObject,
    slot: Omit<WidgetSlot, 'placement'>,
  ) {
    const hostRef = this.getWidgetHostRef(documentServicesAPI, slot.compRef);
    const { widgetId, appDefinitionId } =
      documentServicesAPI.components.data.get(hostRef);
    return {
      appDefinitionId,
      widgetId,
      slotId: slot.role,
    };
  },
  getSlotsFromDm(
    documentServicesAPI: DocumentServicesObject,
    widgetCompRef: CompRef,
  ): Omit<WidgetSlot, 'placement'>[] {
    try {
      return documentServicesAPI.components.slots.getWidgetSlots(
        widgetCompRef,
      ) as Omit<WidgetSlot, 'placement'>[]; // incopatibility between inner and DM types, need to fix;
    } catch (e) {
      this.reportGetWidgetSlotsError(e);
      return [];
    }
  },
  reportGetWidgetSlotsError(error: any) {
    ErrorReporter.captureException(error, {
      tags: { widgetPlugins: true },
    });
    console.error(error);
  },
  getWidgetHostRef(
    documentServicesAPI: DocumentServicesObject,
    compRef: CompRef,
  ): CompRef {
    return documentServicesAPI.components.refComponents.isReferredComponent(
      compRef,
    )
      ? documentServicesAPI.components.refComponents.getRootRefHostCompPointer(
          compRef,
        )
      : compRef;
  },
  async installWidgetPlugin(
    documentServicesAPI: DocumentServicesObject,
    slotComponentRef: CompRef,
    widgetPointer: WidgetPointer,
  ): Promise<CompRef> {
    const appData = documentServicesAPI.tpa.app.getDataByAppDefId(
      widgetPointer.appDefinitionId,
    );

    const widgetComponent = await createWidgetPluginComponentDefinition(
      documentServicesAPI,
      widgetPointer,
      appData.applicationId!,
    );

    const compRef: CompRef = documentServicesAPI.components.slots.populate(
      slotComponentRef,
      defaultSlotName,
      widgetComponent,
    );
    return compRef;
  },
  uninstallWidgetPlugin(
    documentServicesAPI: DocumentServicesObject,
    slotComponentRef: CompRef,
  ) {
    documentServicesAPI.components.slots.remove(
      slotComponentRef,
      defaultSlotName,
    );
  },
  getWidgetPointer(
    documentServicesAPI: DocumentServicesObject,
    slotPlaceholderCompRef: CompRef,
  ): WidgetPointer | undefined {
    const contentRef = documentServicesAPI.components.slots.getSlotsData(
      slotPlaceholderCompRef,
    )[defaultSlotName];

    if (!contentRef) {
      return undefined;
    }

    const { appDefinitionId, widgetId } =
      documentServicesAPI.components.data.get(contentRef);

    return { appDefinitionId, widgetId };
  },
};
