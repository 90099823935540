import {
  BundleDescriptor,
  registerBundle,
} from '@wix/responsive-editor-bundle-infra';

import { EditorPlatformHostIntegrationPackage } from '../editorHostIntegrationPackage';

const packages = { EditorPlatformHostIntegrationPackage };

export const bundleDescriptor: BundleDescriptor = {
  packages,
};

registerBundle('editor-platform-host-integration', bundleDescriptor);
