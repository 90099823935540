import { PageRef, RoutersDefinition } from '@wix/document-services-types';
import { getPageRoleByPageId } from './pages';

type Replacer = { role: string; active: boolean };

export type Replacers = Replacer[];

type ReplacersMap = Record<string, Replacers>;

function getReplacersMap(config: string): ReplacersMap {
  return JSON.parse(config)?.replacers || {};
}

function getReplacersByPageRole(config: string, pageRole: string): Replacers {
  const replacersMap = getReplacersMap(config);
  return replacersMap[pageRole] || [];
}

function getReplacer(
  config: string,
  pages: RoutersDefinition['pages'],
  pageRef: PageRef,
) {
  const replacersMap = getReplacersMap(config);
  const role = getPageRoleByPageId(pages, pageRef.id);
  if (role && replacersMap) {
    return Object.values(replacersMap)
      .flat()
      .find((replacer) => replacer.role === role);
  }
  return undefined;
}

export { getReplacer, getReplacersMap, getReplacersByPageRole };
