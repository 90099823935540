(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("repluggable"), require("lodash"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["repluggable", "lodash", "react"], factory);
	else if(typeof exports === 'object')
		exports["EditorPlatformHostIntegration"] = factory(require("repluggable"), require("lodash"), require("react"));
	else
		root["EditorPlatformHostIntegration"] = factory(root["repluggable"], root["_"], root["React"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__557__, __WEBPACK_EXTERNAL_MODULE__484__, __WEBPACK_EXTERNAL_MODULE__5329__) => {
return 