import { platformEvents } from '../../platformEvents';
import * as flowPlatformApps from './flowPlatformApps';
import * as platformPostMessageService from './platformPostMessageService';
import { InitAppOptions } from '@wix/editor-platform-host-integration-apis';
import workerService from '../services/workerService';
import { init as initCanvasOverlayService } from '../services/canvasOverlayService';
import { DocumentServicesObject } from '@wix/document-services-types';
import { PlatformApiMethods } from '../../types/platformApi';
import { enhance } from './platformApiEnhancer';
import { IWorkspaceApiAdapter } from '../utils/workspaceApiAdapter';
import { IExperimentsApiAdapter } from '../utils/experimentsApiAdapter';
import { init as initToolsEntryService } from '../services/toolsEntryService';
import { init as initViewStateService } from '../services/viewStateService';
import { init as initFeedbackLoopService } from '../services/feedbackService';
import {
  createBiLogger,
  reportBi,
  PLATFORM_INIT,
  PLATFORM_ERROR,
} from '../../essentials';
import { initialize as initI18n, setLanguage } from '../../essentials/i18n';
import { createUrlDecorator } from '../utils/urlDecorator';
import { getHostTypeFromInitOptions } from '../../utils';
import { PlatformWorkerManager } from '@wix/editor-platform-worker';
import { componentRemovedHook } from '../services/unifiedComponents/editorExperiences/removeComponent';
import { initBuilderServices } from '../services/builderServices/init';

import 'reflect-metadata';

initI18n({
  locale: 'en',
});

let workerManagerPromiseResolve: any;

const workerManagerPromise = new Promise<PlatformWorkerManager>((resolve) => {
  workerManagerPromiseResolve = resolve;
});

export async function getWorkerManager() {
  return workerManagerPromise;
}

export async function initNewPlatform(
  apis: {
    documentServicesAPI: DocumentServicesObject;
    workspaceAPI: IWorkspaceApiAdapter;
    experimentsAPI: IExperimentsApiAdapter;
  },
  platformApiMethods: PlatformApiMethods,
  initAppOptions?: InitAppOptions,
) {
  await import(
    /* webpackChunkName: "EditorPlatformSDK2.0" */ './initNewPlatform'
  ).then(async ({ initNewPlatform: _initNewPlatform }) => {
    const workerManager = await _initNewPlatform({
      // @ts-expect-error these fns need to be typed as HostApi from SDK 2.0
      host:
        window.commonConfig?.host?.toLowerCase() ||
        getHostTypeFromInitOptions(initAppOptions),
      // TODO: we will require editor to pass HostAPI in future, for now we just wrap it and add types for what we use.
      __temporaryPlatformApiMethods: platformApiMethods,
      documentServicesAPI: apis.documentServicesAPI,
    });
    workerManagerPromiseResolve(workerManager);
  });
}

export function init(
  apis: {
    documentServicesAPI: DocumentServicesObject;
    workspaceAPI: IWorkspaceApiAdapter;
    experimentsAPI: IExperimentsApiAdapter;
  },
  platformApiMethods: PlatformApiMethods,
  initAppOptions?: InitAppOptions,
  extraNamespaces?: string[],
) {
  const { workspaceAPI, documentServicesAPI, experimentsAPI } = apis;

  try {
    createBiLogger({
      userId: documentServicesAPI.generalInfo.getUserInfo().userId,
      metaSiteId: documentServicesAPI.generalInfo.getMetaSiteId(),
      editorSessionId: platformApiMethods.editor.info.getEditorSessionId(),
      editorType:
        window.commonConfig?.brand ||
        getHostTypeFromInitOptions(initAppOptions),
    });
  } catch (e) {
    console.error('failed to create bi logger', e);
  }

  const currentLanguage = documentServicesAPI.generalInfo.getLanguage();
  setLanguage(currentLanguage);

  enhance(platformApiMethods, {
    documentServices: documentServicesAPI,
    experimentsAPI,
    urlDecorator: createUrlDecorator(initAppOptions),
  });

  if (
    experimentsAPI.enabled('specs.responsive-editor.enablePlatform2_0') ||
    experimentsAPI.enabled('se_enablePlatform2_0')
  ) {
    reportBi(PLATFORM_INIT, {
      step: 'start',
    });

    initNewPlatform(apis, platformApiMethods, initAppOptions)
      .then(() => {
        reportBi(PLATFORM_INIT, {
          step: 'end',
        });
      })
      .catch((e: any) => {
        console.log(e);

        reportBi(PLATFORM_ERROR, {
          errorMessage: e.toString(),
          errorDetails: e.stack,
          name: e.name,
          errorType: e.name,
          errorCode: e.code,
        });
      });
  } else {
    workerManagerPromiseResolve(null);
  }

  const apiForSDK =
    documentServicesAPI.platform.getAPIForSDK(platformApiMethods);

  platformPostMessageService.setEditorAPI(
    documentServicesAPI,
    'editorAPI',
    apiForSDK,
  );

  const appsContainer: Worker = documentServicesAPI.platform.init(
    platformApiMethods,
    { extraNamespaces },
    initAppOptions,
  );

  workerService.setWorker('internal', appsContainer);

  flowPlatformApps.runFlowPlatformApps(documentServicesAPI);

  const onSetAppAPI = ({ apiName }: { apiName: string }) =>
    platformPostMessageService.onSetAppPublicAPI(documentServicesAPI, apiName);

  documentServicesAPI.platform.registerToPublicApiSet(onSetAppAPI);
  documentServicesAPI.platform.registerToPrivateApiSet(onSetAppAPI);

  documentServicesAPI.platform.registerToComponentRemoved(
    componentRemovedHook(platformApiMethods, documentServicesAPI),
  );

  initCanvasOverlayService(documentServicesAPI, workspaceAPI);

  const builderServices = initBuilderServices(
    documentServicesAPI,
    experimentsAPI,
    initAppOptions?.origin?.type === 'RESPONSIVE',
  );

  initToolsEntryService(documentServicesAPI);

  initViewStateService(documentServicesAPI, experimentsAPI);
  initFeedbackLoopService(documentServicesAPI);

  // don't initiate event propagation in non-browser environments (i.e. unit tests)
  if (typeof MessageChannel !== 'undefined') {
    platformEvents.propagation.initMainFrame();
  }

  return {
    platformApiMethods,
    manifestServices: builderServices.manifest,
    panelsServices: builderServices.panels,
  };
}
