import { CompRef, DocumentServicesObject } from '@wix/document-services-types';
import { platformEvents } from '../../../../platformEvents';
import {
  PluginsHostApiContext,
  WidgetPointer,
  WidgetSlot,
} from '../../../../types/widgetPlugins';
import {
  reportinPluginBi,
  serializePlacement,
  virtualSlotPlaceholderSeparator,
} from '../utils/pluginUtils';
import { tpaHostAPI } from './tpaHostAPI';
import { blocksHostAPI } from './blocksHostAPI';
import {
  addPluginTry,
  pluginAdded,
  pluginRemoved,
} from '@wix/bi-logger-editor-platform/v2';

export const resolveHostAPIBySlotRef = (slotPlaceholderCompRef: CompRef) => {
  return slotPlaceholderCompRef.id.includes(virtualSlotPlaceholderSeparator)
    ? tpaHostAPI
    : blocksHostAPI;
};

export const hostApi = (
  documentServicesAPI: DocumentServicesObject,
  context: PluginsHostApiContext,
) => ({
  getWidgetSlot(slotCompRef: CompRef) {
    return resolveHostAPIBySlotRef(slotCompRef).getWidgetSlot(
      documentServicesAPI,
      slotCompRef,
    );
  },
  resolveHostAPIBySlotRef(slotPlaceholderCompRef: CompRef) {
    return slotPlaceholderCompRef.id.includes(virtualSlotPlaceholderSeparator)
      ? tpaHostAPI
      : blocksHostAPI;
  },
  getWidgetSlotPlacement(slotComponentRef: CompRef): WidgetSlot['placement'] {
    return resolveHostAPIBySlotRef(slotComponentRef).getWidgetSlot(
      documentServicesAPI,
      slotComponentRef,
    )?.placement;
  },
  async installWidgetPlugin(
    slotComponentRef: CompRef,
    widgetPointer: WidgetPointer,
  ): Promise<CompRef> {
    const placement: WidgetSlot['placement'] =
      this.getWidgetSlotPlacement(slotComponentRef);
    reportinPluginBi(
      addPluginTry({
        origin: context.origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        plugin_id: widgetPointer.widgetId,
        role: 'user',
        host_app_id: placement.appDefinitionId,
        editorType: context.editorType,
      }),
    );
    const compRef = await resolveHostAPIBySlotRef(
      slotComponentRef,
    ).installWidgetPlugin(documentServicesAPI, slotComponentRef, widgetPointer);

    reportinPluginBi(
      pluginAdded({
        origin: context.origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        placement_id: serializePlacement(placement),
        host_app_id: placement.appDefinitionId,
        app_def_id_plugin: widgetPointer.appDefinitionId,
        plugin_id: widgetPointer.widgetId,
        role: 'user',
        editorType: context.editorType,
      }),
    );
    const installationViaSdk = context.origin === 'sdk';

    if (installationViaSdk) {
      return compRef;
    }

    await documentServicesAPI.waitForChangesAppliedAsync();

    const { applicationId } = documentServicesAPI.tpa.app.getDataByAppDefId(
      placement.appDefinitionId!,
    );
    const hostComponentRef = resolveHostAPIBySlotRef(
      slotComponentRef,
    ).getWidgetHostRef(documentServicesAPI, slotComponentRef);

    documentServicesAPI.platform.notifyApplication(
      applicationId!,
      platformEvents.factory.widgetPluginAdded({
        slotComponentRef,
        hostComponentRef,
        placement: {
          appDefinitionId: placement.appDefinitionId!,
          widgetId: placement.widgetId!,
          slotId: placement.slotId!,
        },
        plugin: widgetPointer,
      }),
    );

    return compRef;
  },
  async uninstallWidgetPlugin(slotComponentRef: CompRef): Promise<void> {
    const uninstallationInBlocks = context.origin === 'blocksAppEditor';

    resolveHostAPIBySlotRef(slotComponentRef).uninstallWidgetPlugin(
      documentServicesAPI,
      slotComponentRef,
    );

    const widgetPointer = this.getWidgetPointer(slotComponentRef);
    const placement = this.getWidgetSlotPlacement(slotComponentRef);
    reportinPluginBi(
      pluginRemoved({
        origin: context.origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        placement_id: serializePlacement(placement),
        host_app_id: placement.appDefinitionId,
        plugin_id: widgetPointer?.widgetId,
        role: uninstallationInBlocks ? 'dev' : 'user',
        editorType: context.editorType,
      }),
    );
  },
  getWidgetPointer(slotPlaceholderCompRef: CompRef): WidgetPointer | undefined {
    return resolveHostAPIBySlotRef(slotPlaceholderCompRef).getWidgetPointer(
      documentServicesAPI,
      slotPlaceholderCompRef,
    );
  },
});
