import { Shell } from 'repluggable';
import { default as api } from './apis/api';
import {
  CompRef,
  PageRef,
  DocumentServicesObject,
  RouterRef,
  AppData as DmAppData,
} from '@wix/document-services-types';
import { DocumentServicesAPI } from '@wix/editorx-core-api';
import type {
  EditorPlatformHostIntegrationAPI,
  PluginInstallationParams,
} from '@wix/editor-platform-host-integration-apis';
import {
  InitAppOptions,
  InstallCallbacks,
  InstallOptions,
  SilentInstallAppsOptions,
  SilentInstallAppsConfig,
  SilentInstallAppsCallbacks,
} from '@wix/editor-platform-host-integration-apis';
import {
  AppData,
  ComponentRef,
  PageVariant,
  Rule,
} from '@wix/editor-platform-sdk-types';
import { initV2 } from './apis/bootstrap/initV2';
import {
  createWorkspaceApi,
  IWorkspaceApiAdapter,
} from './apis/utils/workspaceApiAdapter';
import {
  createExperimentsApi,
  IExperimentsApiAdapter,
} from './apis/utils/experimentsApiAdapter';
import { platformEvents } from './platformEvents';
import { reportBi } from './essentials';
import { PlatformApiMethods, PlatformContext } from './types/platformApi';
import { installApps } from './apis/services/unifiedComponents/installation/addApps';
import { initBuilderServices } from './apis/services/builderServices/init';
import { PluginsHostApiContext } from './types/widgetPlugins';

// these variables are being initiated in the "init" function. init function is called only once, but createEditorPlatformHostIntegrationAPI
// can be called multiple times, so need to save and share them
let context: PlatformContext;
let manifestServices: ReturnType<typeof initBuilderServices>['manifest'];
let panelsServices: ReturnType<typeof initBuilderServices>['panels'];

// new API is created for each host (can be multiple)
export function createEditorPlatformHostIntegrationAPI(
  shell: Shell,
): EditorPlatformHostIntegrationAPI {
  const documentServicesAPI = shell.getAPI(
    DocumentServicesAPI,
  ) as unknown as DocumentServicesObject;
  const workspaceAPI: IWorkspaceApiAdapter = createWorkspaceApi(shell);
  const experimentsAPI: IExperimentsApiAdapter = createExperimentsApi(shell);

  return {
    init: (
      platformApiMethods: PlatformApiMethods,
      initAppOptions?: InitAppOptions,
      extraNamespaces?: string[],
    ) => {
      const {
        platformApiMethods: platformAPIMethods,
        manifestServices: ms,
        panelsServices: ps,
      } = api.init(
        {
          workspaceAPI,
          documentServicesAPI,
          experimentsAPI,
        },
        platformApiMethods,
        initAppOptions,
        extraNamespaces,
      );

      context = {
        platformApiMethods: platformAPIMethods,
        manifestServices: ms,
      };
      manifestServices = ms;
      panelsServices = ps;
    },
    initV2,
    getWorkerManager: () => api.getWorkerManager(),
    setEditorAPI: (namespace: string, platformAPI: any) =>
      api.setEditorAPI(documentServicesAPI, namespace, platformAPI),
    applications: {
      setAppPublicAPI: (apiName: string) =>
        api.applications.setAppPublicAPI(documentServicesAPI, apiName),
      createDependenciesDriver: (
        appDefinitionId: string,
        appVersion?: string,
      ) =>
        api.applications.createDependenciesDriver(
          documentServicesAPI,
          appDefinitionId,
          appVersion,
        ),
      unifiedComponents: {
        installApps: (
          appDefinitionIds: string[],
          installOptions: InstallOptions,
          callbacks?: InstallCallbacks,
        ) =>
          installApps(
            context,
            documentServicesAPI,
            appDefinitionIds,
            installOptions,
            callbacks,
          ),
      },
      install: (
        appDefinitionIds: string[],
        installOptions?: InstallOptions,
        callbacks?: InstallCallbacks,
      ) =>
        api.applications.install(
          context,
          documentServicesAPI,
          experimentsAPI,
          appDefinitionIds,
          installOptions,
          callbacks,
        ),
      fetchNames: (appDefIds: string[]) =>
        api.applications.fetchNames(appDefIds),
      silentInstall: (
        appsToInstallSilently: string[],
        options: SilentInstallAppsOptions,
        serviceConfig: SilentInstallAppsConfig,
        serviceCallbacks: Partial<SilentInstallAppsCallbacks>,
      ) =>
        api.applications.silentInstall(
          context,
          documentServicesAPI,
          experimentsAPI,
          appsToInstallSilently,
          options,
          serviceConfig,
          serviceCallbacks,
        ),
      isSilentInstallRunning: () => api.applications.isSilentInstallRunning(),
    },
    pages: {
      isPageEssential: (pageId: string) =>
        api.pages.isPageEssential(documentServicesAPI, pageId),
      isPageDuplicatable: (pageId: string) =>
        api.pages.isPageDuplicatable(documentServicesAPI, pageId),
    },
    components: {
      getComponentApp: (compRef: CompRef) =>
        api.components.getComponentApp(documentServicesAPI, compRef),
      handleEssentialsComponentsBeforeDelete: (compRefs: CompRef[]) =>
        api.components.handleEssentialsComponentsBeforeDelete(
          context,
          documentServicesAPI,
          compRefs,
        ),
      getPlatformizedComponentData: (componentRef: CompRef) =>
        api.components.getPlatformizedComponentData(
          documentServicesAPI,
          componentRef,
        ),
      isWidgetStretchable: (compRef: CompRef, appData?: DmAppData) =>
        api.components.isWidgetStretchable(
          documentServicesAPI,
          compRef,
          appData,
        ),
      getComponentIdentifiers: (compRef: CompRef, appData?: DmAppData) =>
        api.components.getComponentIdentifiers(
          documentServicesAPI,
          compRef,
          appData,
        ),
      isComponentEssential: (compRef: CompRef) =>
        api.components.isComponentEssentialByRef(documentServicesAPI, compRef),
    },
    sdk: {
      overrideImplementation: api.sdk.overrideImplementation,
    },
    pageReplace: {
      setReplacerPage: (
        pageRef: PageRef,
        replacerPageRef: PageRef,
        setAsActive: boolean,
      ) =>
        api.pageReplace.setReplacerPage(documentServicesAPI, {
          replacerPageRef,
          pageRef,
          setAsActive,
        }),
      openPageVariantsPanel: (
        pageTitle: string,
        variants: PageVariant[],
        pageManagingAppDefId: string,
        pageRef: PageRef,
      ) =>
        api.pageReplace.openPageVariantsPanel(documentServicesAPI, context, {
          pageTitle,
          variants,
          pageManagingAppDefId,
          pageRef,
        }),
      addVariantToPage: (
        pageRef: PageRef,
        variantPageRef: PageRef,
        rule: Rule,
        variantId: string,
      ) =>
        api.pageReplace.addVariantToPage(documentServicesAPI, {
          pageRef,
          variantPageRef,
          rule,
          variantId,
        }),
      getPageInfo: (pageRef: PageRef) =>
        api.pageReplace.getPageInfo(documentServicesAPI, pageRef),
      getPageVariations: (pageRef: PageRef) =>
        api.pageReplace.getPageVariations(documentServicesAPI, pageRef),
      isReplaceable: (appDefinitionId: string, pageRef: PageRef) =>
        api.pageReplace.isReplaceable(
          documentServicesAPI,
          appDefinitionId,
          pageRef,
        ),
      getPageAvailableVariants: (appDefinitionId: string, pageRef: PageRef) =>
        api.pageReplace.getPageAvailableVariants(
          documentServicesAPI,
          appDefinitionId,
          pageRef,
        ),
      getVariantsHelpUrl: (appDefinitionId: string, pageRef: PageRef) =>
        api.pageReplace.getVariantsHelpUrl(
          documentServicesAPI,
          appDefinitionId,
          pageRef,
        ),
      getOriginalPageRef: (pageRef: PageRef) =>
        api.pageReplace.getOriginalPageRef(documentServicesAPI, pageRef),
      isReplacer: (pageRef: PageRef) =>
        api.pageReplace.isReplacer(documentServicesAPI, pageRef),
      isVariant: (pageRef: PageRef) =>
        api.pageReplace.isVariant(documentServicesAPI, pageRef),
      isReplacerActive: (pageRef: PageRef) =>
        api.pageReplace.isReplacerActive(documentServicesAPI, pageRef),
      isRouterExistForPage: (pageRef: PageRef) =>
        api.pageReplace.isRouterExistForPage(documentServicesAPI, pageRef),
      createRouter: (pageRef: PageRef) =>
        api.pageReplace.createRouter(documentServicesAPI, pageRef),
      connectPageToRouter: (routerRef: RouterRef, pageRef: PageRef) =>
        api.pageReplace.connectPageToRouter(
          documentServicesAPI,
          routerRef,
          pageRef,
        ),
      createBlankReplacerPage: (pageRef: PageRef) =>
        api.pageReplace.createBlankReplacerPage(
          context,
          documentServicesAPI,
          pageRef,
        ),
      openDeleteReplacerPageModal: (pageRef: PageRef) =>
        api.pageReplace.openDeleteReplacerPageModal(
          context,
          documentServicesAPI,
          pageRef,
        ),
    },
    viewState: {
      getViewState: (appData: AppData, componentRef: ComponentRef) => {
        return api.viewState.getViewState(
          documentServicesAPI,
          experimentsAPI,
          appData,
          componentRef,
        );
      },
      setViewState: (
        appData: AppData,
        componentRef: ComponentRef,
        viewState: string,
      ) => {
        api.viewState.setViewState(
          documentServicesAPI,
          experimentsAPI,
          appData,
          componentRef,
          viewState,
        );
      },
    },
    canvasOverlay: api.canvasOverlay,
    toolsEntry: {
      register: (listener) => {
        api.toolsEntry.toolsEntryStore.register(listener);
      },
      unregister: (listener) => {
        api.toolsEntry.toolsEntryStore.unregister(listener);
      },
    },
    platformEvents,
    slots: {
      getPluginInstallationParams: (appDefinitionId, components) =>
        api.slots.getPluginInstallationParams(
          documentServicesAPI,
          appDefinitionId,
          components,
        ),
      autoInstall: (
        pluginInstallationParams: PluginInstallationParams,
        pluginsHostAPIContext: PluginsHostApiContext,
      ) =>
        api.slots.autoInstall.install(
          documentServicesAPI,
          pluginInstallationParams,
          pluginsHostAPIContext,
        ),
      autoInstallWithNavigation: (
        pluginInstallationParams: PluginInstallationParams,
        pluginsHostAPIContext: PluginsHostApiContext,
      ) => {
        return api.slots.autoInstall.installWithNavigation(
          context,
          documentServicesAPI,
          pluginInstallationParams,
          pluginsHostAPIContext,
        );
      },
      getAllPluginsInstallationParams: (appDefinitionId, components) =>
        api.slots.getAllPluginsInstallationParams(
          documentServicesAPI,
          appDefinitionId,
          components,
        ),
    },

    manifest: {
      behaviors: {
        is: {
          removable: (editorPointer) =>
            manifestServices.behaviors.getValueFromManifest(
              editorPointer,
              'removable',
            ),

          selectable: (editorPointer) =>
            manifestServices.behaviors.getValueFromManifest(
              editorPointer,
              'selectable',
            ),
        },
      },
      get: {
        displayName: (editorPointer) =>
          manifestServices.get.rootManifestData(editorPointer, 'displayName'),
        presets: (editorPointer) =>
          manifestServices.get.rootManifestData(editorPointer, 'presets'),
        styleDefinition: (editorPointer) =>
          manifestServices.get.styleDefinition(editorPointer),
      },

      isBuilderComponentType: (compType) =>
        manifestServices.isBuilderComponentType(compType),
    },
    panels: {
      getDesignPanel: (editorPointer) =>
        panelsServices.getDesignPanel(editorPointer),
    },
    reportBi,
  };
}
