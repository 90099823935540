import { Shell } from 'repluggable';
import { ExperimentsAPI } from '@wix/editorx-core-api';

// Note: Classic has part of X implementation for experiments API
export type IExperimentsApiAdapter = Pick<
  ExperimentsAPI,
  'enabled' | 'all' | 'getValue'
>;

export const createExperimentsApi = (shell: Shell): IExperimentsApiAdapter => {
  const { enabled, all, getValue }: IExperimentsApiAdapter =
    shell.getAPI(ExperimentsAPI);
  return {
    enabled,
    all,
    getValue,
  };
};

// empty implementation for ADI
export const createEmptyExperimentsApi = (): IExperimentsApiAdapter => ({
  enabled: () => false,
  all: () => ({}),
  getValue: () => undefined,
});
